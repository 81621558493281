import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  IconsHomeEngLogo: () => import('../../components/icons/HomeEngLogo.vue' /* webpackChunkName: "components/icons-home-eng-logo" */).then(c => wrapFunctional(c.default || c)),
  IconsHomeLogo: () => import('../../components/icons/HomeLogo.vue' /* webpackChunkName: "components/icons-home-logo" */).then(c => wrapFunctional(c.default || c)),
  IconsOpenIcon: () => import('../../components/icons/OpenIcon.vue' /* webpackChunkName: "components/icons-open-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsSkolkovoIcon: () => import('../../components/icons/SkolkovoIcon.vue' /* webpackChunkName: "components/icons-skolkovo-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsFb: () => import('../../components/icons/fb.vue' /* webpackChunkName: "components/icons-fb" */).then(c => wrapFunctional(c.default || c)),
  IconsInst: () => import('../../components/icons/inst.vue' /* webpackChunkName: "components/icons-inst" */).then(c => wrapFunctional(c.default || c)),
  IconsOd: () => import('../../components/icons/od.vue' /* webpackChunkName: "components/icons-od" */).then(c => wrapFunctional(c.default || c)),
  IconsVk: () => import('../../components/icons/vk.vue' /* webpackChunkName: "components/icons-vk" */).then(c => wrapFunctional(c.default || c)),
  PageOfficialInfoPage: () => import('../../components/page/OfficialInfoPage.vue' /* webpackChunkName: "components/page-official-info-page" */).then(c => wrapFunctional(c.default || c)),
  PagePrivacyPage: () => import('../../components/page/PrivacyPage.vue' /* webpackChunkName: "components/page-privacy-page" */).then(c => wrapFunctional(c.default || c)),
  PartnerAboutSection: () => import('../../components/partner/AboutSection.vue' /* webpackChunkName: "components/partner-about-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerCommunitySection: () => import('../../components/partner/CommunitySection.vue' /* webpackChunkName: "components/partner-community-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerFeedbackSection: () => import('../../components/partner/FeedbackSection.vue' /* webpackChunkName: "components/partner-feedback-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerGallerySection: () => import('../../components/partner/GallerySection.vue' /* webpackChunkName: "components/partner-gallery-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerHintSection: () => import('../../components/partner/HintSection.vue' /* webpackChunkName: "components/partner-hint-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerMapSection: () => import('../../components/partner/MapSection.vue' /* webpackChunkName: "components/partner-map-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerNavigationSection: () => import('../../components/partner/NavigationSection.vue' /* webpackChunkName: "components/partner-navigation-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerNewsSection: () => import('../../components/partner/NewsSection.vue' /* webpackChunkName: "components/partner-news-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerQuestionsSection: () => import('../../components/partner/QuestionsSection.vue' /* webpackChunkName: "components/partner-questions-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerSignupSection: () => import('../../components/partner/SignupSection.vue' /* webpackChunkName: "components/partner-signup-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerSkillsSection: () => import('../../components/partner/SkillsSection.vue' /* webpackChunkName: "components/partner-skills-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerStartSection: () => import('../../components/partner/StartSection.vue' /* webpackChunkName: "components/partner-start-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerStatsSection: () => import('../../components/partner/StatsSection.vue' /* webpackChunkName: "components/partner-stats-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerTeacherSection: () => import('../../components/partner/TeacherSection.vue' /* webpackChunkName: "components/partner-teacher-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerVideoSection: () => import('../../components/partner/VideoSection.vue' /* webpackChunkName: "components/partner-video-section" */).then(c => wrapFunctional(c.default || c)),
  PartnerWorkflowSection: () => import('../../components/partner/WorkflowSection.vue' /* webpackChunkName: "components/partner-workflow-section" */).then(c => wrapFunctional(c.default || c)),
  CommonAlgCopyrightText: () => import('../../components/common/AlgCopyrightText.vue' /* webpackChunkName: "components/common-alg-copyright-text" */).then(c => wrapFunctional(c.default || c)),
  CommonCityList: () => import('../../components/common/CityList.vue' /* webpackChunkName: "components/common-city-list" */).then(c => wrapFunctional(c.default || c)),
  CommonPreloader: () => import('../../components/common/Preloader.vue' /* webpackChunkName: "components/common-preloader" */).then(c => wrapFunctional(c.default || c)),
  CommonPrivacyHeader: () => import('../../components/common/PrivacyHeader.vue' /* webpackChunkName: "components/common-privacy-header" */).then(c => wrapFunctional(c.default || c)),
  CommonShearsDivider: () => import('../../components/common/ShearsDivider.vue' /* webpackChunkName: "components/common-shears-divider" */).then(c => wrapFunctional(c.default || c)),
  CommonSlider: () => import('../../components/common/Slider.vue' /* webpackChunkName: "components/common-slider" */).then(c => wrapFunctional(c.default || c)),
  CommonSocialIcons: () => import('../../components/common/SocialIcons.vue' /* webpackChunkName: "components/common-social-icons" */).then(c => wrapFunctional(c.default || c)),
  CommonTeacherPhotoStyled: () => import('../../components/common/TeacherPhotoStyled.vue' /* webpackChunkName: "components/common-teacher-photo-styled" */).then(c => wrapFunctional(c.default || c)),
  CommonVueModal: () => import('../../components/common/VueModal.vue' /* webpackChunkName: "components/common-vue-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsCityBillet: () => import('../../components/modals/CityBillet.vue' /* webpackChunkName: "components/modals-city-billet" */).then(c => wrapFunctional(c.default || c)),
  ModalsLandingThanksModal: () => import('../../components/modals/LandingThanksModal.vue' /* webpackChunkName: "components/modals-landing-thanks-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsSaleModal: () => import('../../components/modals/SaleModal.vue' /* webpackChunkName: "components/modals-sale-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsThanksModal: () => import('../../components/modals/ThanksModal.vue' /* webpackChunkName: "components/modals-thanks-modal" */).then(c => wrapFunctional(c.default || c)),
  RedesignAllSections: () => import('../../components/redesign/AllSections.vue' /* webpackChunkName: "components/redesign-all-sections" */).then(c => wrapFunctional(c.default || c)),
  RedesignAtmSection: () => import('../../components/redesign/AtmSection.vue' /* webpackChunkName: "components/redesign-atm-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCoursesSection: () => import('../../components/redesign/CoursesSection.vue' /* webpackChunkName: "components/redesign-courses-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignFaqSection: () => import('../../components/redesign/FaqSection.vue' /* webpackChunkName: "components/redesign-faq-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignFooter: () => import('../../components/redesign/Footer.vue' /* webpackChunkName: "components/redesign-footer" */).then(c => wrapFunctional(c.default || c)),
  RedesignFormatSection: () => import('../../components/redesign/FormatSection.vue' /* webpackChunkName: "components/redesign-format-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignHeader: () => import('../../components/redesign/Header.vue' /* webpackChunkName: "components/redesign-header" */).then(c => wrapFunctional(c.default || c)),
  RedesignHowPicsSection: () => import('../../components/redesign/HowPicsSection.vue' /* webpackChunkName: "components/redesign-how-pics-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignHowSection: () => import('../../components/redesign/HowSection.vue' /* webpackChunkName: "components/redesign-how-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignHowVideoSlide: () => import('../../components/redesign/HowVideoSlide.vue' /* webpackChunkName: "components/redesign-how-video-slide" */).then(c => wrapFunctional(c.default || c)),
  RedesignLearnSection: () => import('../../components/redesign/LearnSection.vue' /* webpackChunkName: "components/redesign-learn-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignResultSection: () => import('../../components/redesign/ResultSection.vue' /* webpackChunkName: "components/redesign-result-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignSignupSection: () => import('../../components/redesign/SignupSection.vue' /* webpackChunkName: "components/redesign-signup-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignTeachersSection: () => import('../../components/redesign/TeachersSection.vue' /* webpackChunkName: "components/redesign-teachers-section" */).then(c => wrapFunctional(c.default || c)),
  UIYoutubeIframe: () => import('../../components/UI/YoutubeIframe.vue' /* webpackChunkName: "components/u-i-youtube-iframe" */).then(c => wrapFunctional(c.default || c)),
  UIElButton: () => import('../../components/UI/el-button.vue' /* webpackChunkName: "components/u-i-el-button" */).then(c => wrapFunctional(c.default || c)),
  UIElCheckbox: () => import('../../components/UI/el-checkbox.vue' /* webpackChunkName: "components/u-i-el-checkbox" */).then(c => wrapFunctional(c.default || c)),
  UIElDatePicker: () => import('../../components/UI/el-date-picker.vue' /* webpackChunkName: "components/u-i-el-date-picker" */).then(c => wrapFunctional(c.default || c)),
  UIElInput: () => import('../../components/UI/el-input.vue' /* webpackChunkName: "components/u-i-el-input" */).then(c => wrapFunctional(c.default || c)),
  UIElLink: () => import('../../components/UI/el-link.vue' /* webpackChunkName: "components/u-i-el-link" */).then(c => wrapFunctional(c.default || c)),
  UIElLottie: () => import('../../components/UI/el-lottie.vue' /* webpackChunkName: "components/u-i-el-lottie" */).then(c => wrapFunctional(c.default || c)),
  UIElRadio: () => import('../../components/UI/el-radio.vue' /* webpackChunkName: "components/u-i-el-radio" */).then(c => wrapFunctional(c.default || c)),
  UIElTextarea: () => import('../../components/UI/el-textarea.vue' /* webpackChunkName: "components/u-i-el-textarea" */).then(c => wrapFunctional(c.default || c)),
  PagePartnerMainPartner: () => import('../../components/page/partner/MainPartner.vue' /* webpackChunkName: "components/page-partner-main-partner" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignCamp: () => import('../../components/page/redesign/Camp.vue' /* webpackChunkName: "components/page-redesign-camp" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignCreativePage: () => import('../../components/page/redesign/CreativePage.vue' /* webpackChunkName: "components/page-redesign-creative-page" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignFrontend: () => import('../../components/page/redesign/Frontend.vue' /* webpackChunkName: "components/page-redesign-frontend" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignGameDesign: () => import('../../components/page/redesign/GameDesign.vue' /* webpackChunkName: "components/page-redesign-game-design" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignGraphicDesign: () => import('../../components/page/redesign/GraphicDesign.vue' /* webpackChunkName: "components/page-redesign-graphic-design" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignJuniorPage: () => import('../../components/page/redesign/JuniorPage.vue' /* webpackChunkName: "components/page-redesign-junior-page" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignPcPage: () => import('../../components/page/redesign/PcPage.vue' /* webpackChunkName: "components/page-redesign-pc-page" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignPytPro: () => import('../../components/page/redesign/PytPro.vue' /* webpackChunkName: "components/page-redesign-pyt-pro" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignPytStart: () => import('../../components/page/redesign/PytStart.vue' /* webpackChunkName: "components/page-redesign-pyt-start" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignUnity: () => import('../../components/page/redesign/Unity.vue' /* webpackChunkName: "components/page-redesign-unity" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignVideocontent: () => import('../../components/page/redesign/Videocontent.vue' /* webpackChunkName: "components/page-redesign-videocontent" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignWebSites: () => import('../../components/page/redesign/WebSites.vue' /* webpackChunkName: "components/page-redesign-web-sites" */).then(c => wrapFunctional(c.default || c)),
  CommonFooterPartnerFooter: () => import('../../components/common/footer/PartnerFooter.vue' /* webpackChunkName: "components/common-footer-partner-footer" */).then(c => wrapFunctional(c.default || c)),
  CommonHeaderAlgLoginButton: () => import('../../components/common/header/AlgLoginButton.vue' /* webpackChunkName: "components/common-header-alg-login-button" */).then(c => wrapFunctional(c.default || c)),
  CommonHeaderCitySelector: () => import('../../components/common/header/CitySelector.vue' /* webpackChunkName: "components/common-header-city-selector" */).then(c => wrapFunctional(c.default || c)),
  CommonHeaderMainHeader: () => import('../../components/common/header/MainHeader.vue' /* webpackChunkName: "components/common-header-main-header" */).then(c => wrapFunctional(c.default || c)),
  CommonHeaderPartnerHeader: () => import('../../components/common/header/PartnerHeader.vue' /* webpackChunkName: "components/common-header-partner-header" */).then(c => wrapFunctional(c.default || c)),
  CommonIconsAlgTelegramIcon: () => import('../../components/common/icons/AlgTelegramIcon.vue' /* webpackChunkName: "components/common-icons-alg-telegram-icon" */).then(c => wrapFunctional(c.default || c)),
  CommonIconsAlgWhatsupIcon: () => import('../../components/common/icons/AlgWhatsupIcon.vue' /* webpackChunkName: "components/common-icons-alg-whatsup-icon" */).then(c => wrapFunctional(c.default || c)),
  CommonLinksAlgMotkaLink: () => import('../../components/common/links/AlgMotkaLink.vue' /* webpackChunkName: "components/common-links-alg-motka-link" */).then(c => wrapFunctional(c.default || c)),
  CommonLinksAlgOfferLink: () => import('../../components/common/links/AlgOfferLink.vue' /* webpackChunkName: "components/common-links-alg-offer-link" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionAlgoritmikaStatsSection: () => import('../../components/common/section/AlgoritmikaStatsSection.vue' /* webpackChunkName: "components/common-section-algoritmika-stats-section" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionApplicationFormSection: () => import('../../components/common/section/ApplicationFormSection.vue' /* webpackChunkName: "components/common-section-application-form-section" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionFeedbackSection: () => import('../../components/common/section/FeedbackSection.vue' /* webpackChunkName: "components/common-section-feedback-section" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionMapSection: () => import('../../components/common/section/MapSection.vue' /* webpackChunkName: "components/common-section-map-section" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionModals: () => import('../../components/common/section/Modals.vue' /* webpackChunkName: "components/common-section-modals" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionReservSection: () => import('../../components/common/section/ReservSection.vue' /* webpackChunkName: "components/common-section-reserv-section" */).then(c => wrapFunctional(c.default || c)),
  CommonSectionStartScreenSection: () => import('../../components/common/section/StartScreenSection.vue' /* webpackChunkName: "components/common-section-start-screen-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingClaimSection: () => import('../../components/redesign/coding/ClaimSection.vue' /* webpackChunkName: "components/redesign-coding-claim-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingTop: () => import('../../components/redesign/coding/CodingTop.vue' /* webpackChunkName: "components/redesign-coding-top" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingCommentSection: () => import('../../components/redesign/coding/CommentSection.vue' /* webpackChunkName: "components/redesign-coding-comment-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingCoursesSection: () => import('../../components/redesign/coding/CoursesSection.vue' /* webpackChunkName: "components/redesign-coding-courses-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingFacultyHeader: () => import('../../components/redesign/coding/FacultyHeader.vue' /* webpackChunkName: "components/redesign-coding-faculty-header" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingFeaturesSection: () => import('../../components/redesign/coding/FeaturesSection.vue' /* webpackChunkName: "components/redesign-coding-features-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingRoadmapSection: () => import('../../components/redesign/coding/RoadmapSection.vue' /* webpackChunkName: "components/redesign-coding-roadmap-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingStartSection: () => import('../../components/redesign/coding/StartSection.vue' /* webpackChunkName: "components/redesign-coding-start-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingSummerSection: () => import('../../components/redesign/coding/SummerSection.vue' /* webpackChunkName: "components/redesign-coding-summer-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingWorldSection: () => import('../../components/redesign/coding/WorldSection.vue' /* webpackChunkName: "components/redesign-coding-world-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathCommentSection: () => import('../../components/redesign/math/CommentSection.vue' /* webpackChunkName: "components/redesign-math-comment-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathCoursesSection: () => import('../../components/redesign/math/CoursesSection.vue' /* webpackChunkName: "components/redesign-math-courses-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathFeaturesSection: () => import('../../components/redesign/math/FeaturesSection.vue' /* webpackChunkName: "components/redesign-math-features-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathTop: () => import('../../components/redesign/math/MathTop.vue' /* webpackChunkName: "components/redesign-math-top" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathStartSection: () => import('../../components/redesign/math/StartSection.vue' /* webpackChunkName: "components/redesign-math-start-section" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathWorldSection: () => import('../../components/redesign/math/WorldSection.vue' /* webpackChunkName: "components/redesign-math-world-section" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignCodingPage: () => import('../../components/page/redesign/coding/CodingPage.vue' /* webpackChunkName: "components/page-redesign-coding-page" */).then(c => wrapFunctional(c.default || c)),
  PageRedesignMathPage: () => import('../../components/page/redesign/math/MathPage.vue' /* webpackChunkName: "components/page-redesign-math-page" */).then(c => wrapFunctional(c.default || c)),
  RedesignCodingComponentsButton: () => import('../../components/redesign/coding/components/button/button.vue' /* webpackChunkName: "components/redesign-coding-components-button" */).then(c => wrapFunctional(c.default || c)),
  RedesignMathComponentsButton: () => import('../../components/redesign/math/components/button/button.vue' /* webpackChunkName: "components/redesign-math-components-button" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
