export default function ({ app, route }) {
  const cpaCookieOpts = {
    path: '/',
    maxAge: 60 * 60 * 24 * 90,
  }

  if (process.env.NODE_ENV === 'production') {
    if (route.host === 'probniyurok.algoritmika.org') {
      cpaCookieOpts.domain = 'probniyurok.algoritmika.org'
    } else {
      cpaCookieOpts.domain = 'online.algoritmika.org'
    }
  }

  if (route.query.admitad_uid !== undefined) {
    app.$cookies.set('cpa_param', 'admitad_uid', cpaCookieOpts)
    app.$cookies.set('cpa_value', route.query.admitad_uid, cpaCookieOpts)
  }
}
