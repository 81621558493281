export const newMasks = {
  af: {
    n: 'Afghanistan (افغانستان)',
    c: '+93',
    m: '+93-99-999-9999',
  },
  al: {
    n: 'Albania (Shqipëri)',
    c: '+355',
    m: '+355(999) 999-999',
  },
  dz: {
    n: 'Algeria (الجزائر)',
    c: '+213',
    m: '+213-99-999-9999',
  },
  ad: {
    n: 'Andorra',
    c: '+376',
    m: '+376-999-999',
  },
  ao: {
    n: 'Angola',
    c: '+244',
    m: '+244(999) 999-999',
  },
  am: {
    n: 'Armenia (Հայաստան)',
    c: '+374',
    m: '+374-99-999-999',
  },
  ag: {
    n: 'Antigua and Barbuda',
    c: '+1 (268)',
    m: '+1 (268)999-9999',
  },
  ar: {
    n: 'Argentina',
    c: '+54',
    m: '+54 (99) 9999-9999',
  },
  au: {
    n: 'Australia',
    c: '+61',
    m: '+61-99-9999-9999',
  },
  at: {
    n: 'Austria (Österreich)',
    c: '+43',
    m: '+43(999) 999-99999',
  },
  az: {
    n: 'Azerbaijan (Azərbaycan)',
    c: '+994',
    m: '+994-99-999-99-99',
  },
  bs: {
    n: 'Bahamas',
    c: '+1 (242)',
    m: '+1 (242)999-9999',
  },
  bh: {
    n: 'Bahrain (البحرين)',
    c: '+973',
    m: '+973-9999-9999',
  },
  bd: {
    n: 'Bangladesh (বাংলাদেশ)',
    c: '+880',
    m: '+880-9999-999999',
  },
  bb: {
    n: 'Barbados',
    c: '+1 (246)',
    m: '+1 (246)999-9999',
  },
  by: {
    n: 'Belarus (Беларусь)',
    c: '+375',
    m: '+375(99) 999-99-99',
  },
  be: {
    n: 'Belgium (België)',
    c: '+32',
    m: '+32(999) 999-999',
  },
  bz: {
    n: 'Belize',
    c: '+501',
    m: '+501-999-9999',
  },
  bj: {
    n: 'Benin (Bénin)',
    c: '+229',
    m: '+229-99-99-9999',
  },
  bt: {
    n: 'Bhutan (འབྲུག)',
    c: '+975',
    m: '+975-9-999-9999',
  },
  bo: {
    n: 'Bolivia',
    c: '+591',
    m: '+591-9-999-9999',
  },
  ba: {
    n: 'Bosnia and Herzegovina',
    c: '+387',
    m: '+387-99-999-999',
  },
  bw: {
    n: 'Botswana',
    c: '+267',
    m: '+267-99-999-999',
  },
  br: {
    n: 'Brazil (Brasil)',
    c: '+55',
    m: '+55(99) 99999-9999',
  },
  bn: {
    n: 'Brunei',
    c: '+673',
    m: '+673-999-9999',
  },
  bg: {
    n: 'Bulgaria (България)',
    c: '+359',
    m: '+359(999) 999-999',
  },
  bf: {
    n: 'Burkina Faso',
    c: '+226',
    m: '+226-99-99-9999',
  },
  bi: {
    n: 'Burundi (Uburundi)',
    c: '+257',
    m: '+257-99-99-9999',
  },
  kh: {
    n: 'Cambodia (កម្ពុជា)',
    c: '+855',
    m: '+855-99-999-999',
  },
  cm: {
    n: 'Cameroon (Cameroun)',
    c: '+237',
    m: '+237-9-99-99-99-99',
  },
  ca: {
    n: 'Canada',
    c: '+1',
    m: '+1(999) 999-9999',
  },
  cv: {
    n: 'Cape Verde (Kabu Verdi)',
    c: '+238',
    m: '+238(999) 99-99',
  },
  cf: {
    n: 'Central African Republic (République centrafricaine)',
    c: '+236',
    m: '+236-99-99-9999',
  },
  td: {
    n: 'Chad (Tchad)',
    c: '+235',
    m: '+235-99-99-99-99',
  },
  cl: {
    n: 'Chile',
    c: '+56',
    m: '+56-9-9999-9999',
  },
  cn: {
    n: 'China (中国)',
    c: '+86',
    m: '+86(999) 9999-9999',
  },
  co: {
    n: 'Colombia',
    c: '+57',
    m: '+57(999) 999-9999',
  },
  km: {
    n: 'Comoros (جزر القمر)',
    c: '+269',
    m: '+269-99-99999',
  },
  cd: {
    n: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    c: '+243',
    m: '+243(999) 999-999',
  },
  cg: {
    n: 'Congo (Republic) (Congo-Brazzaville)',
    c: '+242',
    m: '+242-99-999-9999',
  },
  ck: {
    n: 'Cook Islands',
    c: '+682',
    m: '+682-99-999',
  },
  cr: {
    n: 'Costa Rica',
    c: '+506',
    m: '+506-9999-9999',
  },
  ci: {
    n: 'Cote d’Ivoire',
    c: '+225',
    m: '+225-99-999-999',
  },
  hr: {
    n: 'Croatia (Hrvatska)',
    c: '+385',
    m: '+385-99-999-9999',
  },
  cu: {
    n: 'Cuba',
    c: '+53',
    m: '+53-9-999-9999',
  },
  cy: {
    n: 'Cyprus (Κύπρος)',
    c: '+357',
    m: '+357-99-999-999',
  },
  cz: {
    n: 'Czech Republic (Česká republika)',
    c: '+420',
    m: '+420(999) 999-999',
  },
  dk: {
    n: 'Denmark (Danmark)',
    c: '+45',
    m: '+45-99-99-99-99',
  },
  dj: {
    n: 'Djibouti',
    c: '+253',
    m: '+253-99-99-99-99',
  },
  dm: {
    n: 'Dominica',
    c: '+1 (767)',
    m: '+1 (767)999-9999',
  },
  do: {
    n: 'Dominican Republic (República Dominicana)',
    c: '+1',
    m: '+1(999) 999-9999',
  },
  ec: {
    n: 'Ecuador',
    c: '+593',
    m: '+593-99-999-9999',
  },
  eg: {
    n: 'Egypt (مصر)',
    c: '+20',
    m: '+20(999) 999-9999',
  },
  sv: {
    n: 'El Salvador',
    c: '+503',
    m: '+503-99-99-9999',
  },
  gq: {
    n: 'Equatorial Guinea (Guinea Ecuatorial)',
    c: '+240',
    m: '+240-99-999-9999',
  },
  er: {
    n: 'Eritrea',
    c: '+291',
    m: '+291-9-999-999',
  },
  ee: {
    n: 'Estonia (Eesti)',
    c: '+372',
    m: '+372-9999-9999',
  },
  et: {
    n: 'Ethiopia',
    c: '+251',
    m: '+251-99-999-9999',
  },
  fj: {
    n: 'Fiji',
    c: '+679',
    m: '+679-999-9999',
  },
  fi: {
    n: 'Finland (Suomi)',
    c: '+358',
    m: '+358-999-9999999',
  },
  fr: {
    n: 'France',
    c: '+33',
    m: '+33 99 99 99 99',
  },
  ga: {
    n: 'Gabon',
    c: '+241',
    m: '+241-9-99-99-99',
  },
  gm: {
    n: 'Gambia',
    c: '+220',
    m: '+220(999) 99-99',
  },
  ge: {
    n: 'Georgia (საქართველო)',
    c: '+995',
    m: '+995(999) 999-999',
  },
  de: {
    n: 'Germany (Deutschland)',
    c: '+49',
    m: '+49(999) 999-99999',
  },
  gh: {
    n: 'Ghana (Gaana)',
    c: '+233',
    m: '+233(999) 999-999',
  },
  gr: {
    n: 'Greece (Ελλάδα)',
    c: '+30',
    m: '+30(999) 999-9999',
  },
  gd: {
    n: 'Grenada',
    c: '+1 (473)',
    m: '+1 (473)999-9999',
  },
  gt: {
    n: 'Guatemala',
    c: '+502',
    m: '+502-9999-9999',
  },
  gn: {
    n: 'Guinea (Guinée)',
    c: '+224',
    m: '+224-999-99-99-99',
  },
  gw: {
    n: 'Guinea-Bissau (Guiné Bissau)',
    c: '+245',
    m: '+245-9-999999',
  },
  gy: {
    n: 'Guyana',
    c: '+592',
    m: '+592-999-9999',
  },
  ht: {
    n: 'Haiti',
    c: '+509',
    m: '+509-99-99-9999',
  },
  hn: {
    n: 'Honduras',
    c: '+504',
    m: '+504-9999-9999',
  },
  hk: {
    n: 'Hong Kong (香港)',
    c: '+852',
    m: '+852-9999-9999',
  },
  hu: {
    n: 'Hungary (Magyarország)',
    c: '+36',
    m: '+36(999) 999-999',
  },
  is: {
    n: 'Iceland (Ísland)',
    c: '+354',
    m: '+354-999-9999',
  },
  in: {
    n: 'India (भारत)',
    c: '+91',
    m: '+91(9999) 999-999',
  },
  id: {
    n: 'Indonesia',
    c: '+62',
    m: '+62(999) 999-99-999',
  },
  ir: {
    n: 'Iran (ایران)',
    c: '+98',
    m: '+98(999) 999-9999',
  },
  iq: {
    n: 'Iraq (العراق)',
    c: '+964',
    m: '+964(999) 999-9999',
  },
  ie: {
    n: 'Ireland',
    c: '+353',
    m: '+353(999) 999-999',
  },
  il: {
    n: 'Israel (ישראל)',
    c: '+972',
    m: '+972-999-999-9999',
  },
  it: {
    n: 'Italy (Italia)',
    c: '+39',
    m: '+39(999) 9999-999',
  },
  jm: {
    n: 'Jamaica',
    c: '+1',
    m: '+1(999) 999-9999',
  },
  jp: {
    n: 'Japan (日本)',
    c: '+81',
    m: '+81-99-9999-9999',
  },
  jo: {
    n: 'Jordan (الأردن)',
    c: '+962',
    m: '+962-9-9999-9999',
  },
  kz: {
    n: 'Kazakhstan (Казахстан)',
    c: '+7',
    m: '+7(999) 999-99-99',
  },
  ke: {
    n: 'Kenya',
    c: '+254',
    m: '+254-999-999999',
  },
  ki: {
    n: 'Kiribati',
    c: '+686',
    m: '+686-99-999',
  },
  xk: {
    n: 'Kosovo (Republic)',
    c: '+383',
    m: '+383-99-999-999',
  },
  kw: {
    n: 'Kuwait (الكويت)',
    c: '+965',
    m: '+965-9999-9999',
  },
  kg: {
    n: 'Kyrgyzstan (Кыргызстан)',
    c: '+996',
    m: '+996(999) 999-999',
  },
  la: {
    n: 'Laos (ລາວ)',
    c: '+856',
    m: '+856-99-999-999',
  },
  lv: {
    n: 'Latvia (Latvija)',
    c: '+371',
    m: '+371-99-999-999',
  },
  lb: {
    n: 'Lebanon (لبنان)',
    c: '+961',
    m: '+961-99-999-999',
  },
  ls: {
    n: 'Lesotho',
    c: '+266',
    m: '+266-9-999-9999',
  },
  lr: {
    n: 'Liberia',
    c: '+231',
    m: '+231-99-999-9999',
  },
  ly: {
    n: 'Libya (ليبيا)',
    c: '+218',
    m: '+218-99-999-999',
  },
  li: {
    n: 'Liechtenstein',
    c: '+423',
    m: '+423-999-99-99',
  },
  lt: {
    n: 'Lithuania (Lietuva)',
    c: '+370',
    m: '+370(999) 99-999',
  },
  lu: {
    n: 'Luxembourg',
    c: '+352',
    m: '+352(999) 999-999',
  },
  mo: {
    n: 'Macao',
    c: '+853',
    m: '+853-9999-9999',
  },
  mk: {
    n: 'Macedonia (FYROM) (Македонија)',
    c: '+389',
    m: '+389-99-999-999',
  },
  mg: {
    n: 'Madagascar (Madagasikara)',
    c: '+261',
    m: '+261-99-99-99999',
  },
  mw: {
    n: 'Malawi',
    c: '+265',
    m: '+265-9-9999-9999',
  },
  my: {
    n: 'Malaysia',
    c: '+60',
    m: '+60-99-999-9999',
  },
  mv: {
    n: 'Maldives',
    c: '+960',
    m: '+960-999-9999',
  },
  ml: {
    n: 'Mali',
    c: '+223',
    m: '+223-99-99-9999',
  },
  mt: {
    n: 'Malta',
    c: '+356',
    m: '+356-9999-9999',
  },
  mh: {
    n: 'Marshall Islands',
    c: '+692',
    m: '+692-999-9999',
  },
  mr: {
    n: 'Mauritania (موريتانيا)',
    c: '+222',
    m: '+222-99-99-9999',
  },
  mu: {
    n: 'Mauritius (Moris)',
    c: '+230',
    m: '+230-999-9999',
  },
  mx: {
    n: 'Mexico (México)',
    c: '+52',
    m: '+52(999) 999-9999',
  },
  mb: {
    n: 'Mexico (México)',
    c: '+521',
    m: '+521(999) 999-9999',
  },
  fm: {
    n: 'Micronesia',
    c: '+691',
    m: '+691-999-9999',
  },
  md: {
    n: 'Moldova (Republica Moldova)',
    c: '+373',
    m: '+373-9999-9999',
  },
  mc: {
    n: 'Monaco',
    c: '+377',
    m: '+377-99-999-999',
  },
  mn: {
    n: 'Mongolia (Монгол)',
    c: '+976',
    m: '+976-99-99-9999',
  },
  me: {
    n: 'Montenegro (Crna Gora)',
    c: '+382',
    m: '+382-99-999-999',
  },
  ma: {
    n: 'Morocco (المغرب)',
    c: '+212',
    m: '+212-99-9999-999',
  },
  mz: {
    n: 'Mozambique (Moçambique)',
    c: '+258',
    m: '+258-99-999-999',
  },
  mm: {
    n: 'Myanmar (Burma) (မြန်မာ)',
    c: '+95',
    m: '+95-99-999-999',
  },
  na: {
    n: 'Namibia (Namibië)',
    c: '+264',
    m: '+264-99-999-9999',
  },
  nr: {
    n: 'Nauru',
    c: '+674',
    m: '+674-999-9999',
  },
  np: {
    n: 'Nepal (नेपाल)',
    c: '+977',
    m: '+977-99-999-999',
  },
  nl: {
    n: 'Netherlands (Nederland)',
    c: '+31',
    m: '+31-99-999-9999',
  },
  nz: {
    n: 'New Zealand',
    c: '+64',
    m: '+64(999) 999-999',
  },
  ni: {
    n: 'Nicaragua',
    c: '+505',
    m: '+505-9999-9999',
  },
  ne: {
    n: 'Niger (Nijar)',
    c: '+227',
    m: '+227-99-99-9999',
  },
  ng: {
    n: 'Nigeria',
    c: '+234',
    m: '+234-999-999-9999',
  },
  nu: {
    n: 'Niue',
    c: '+683',
    m: '+683-9999',
  },
  kp: {
    n: 'North Korea (조선 민주주의 인민 공화국)',
    c: '+850',
    m: '+850-99-999-999',
  },
  no: {
    n: 'Norway (Norge)',
    c: '+47',
    m: '+47(999) 99-999',
  },
  om: {
    n: 'Oman (عُمان)',
    c: '+968',
    m: '+968-99-999-999',
  },
  pa: {
    n: 'Panama',
    c: '+507',
    m: '+507 9999-9999',
  },
  pk: {
    n: 'Pakistan (پاکستان)',
    c: '+92',
    m: '+92(999) 999-9999',
  },
  pw: {
    n: 'Palau',
    c: '+680',
    m: '+680-999-9999',
  },
  ps: {
    n: 'Palestinian Territory',
    c: '+970',
    m: '+970 99 999 9999',
  },
  pg: {
    n: 'Papua New Guinea',
    c: '+675',
    m: '+675(999) 99-999',
  },
  py: {
    n: 'Paraguay',
    c: '+595',
    m: '+595(999) 999-999',
  },
  pe: {
    n: 'Peru (Perú)',
    c: '+51',
    m: '+51(999) 999-999',
  },
  ph: {
    n: 'Philippines',
    c: '+63',
    m: '+63(999) 999-9999',
  },
  pl: {
    n: 'Poland (Polska)',
    c: '+48',
    m: '+48(999) 999-999',
  },
  pt: {
    n: 'Portugal',
    c: '+351',
    m: '+351-99-999-9999',
  },
  qa: {
    n: 'Qatar (قطر)',
    c: '+974',
    m: '+974-9999-9999',
  },
  ro: {
    n: 'Romania (România)',
    c: '+40',
    m: '+40-99-999-9999',
  },
  ru: {
    n: 'Russian Federation (Российская Федерация)',
    c: '+7',
    m: '+7(999) 999-99-99',
  },
  rw: {
    n: 'Rwanda',
    c: '+250',
    m: '+250(999) 999-999',
  },
  kn: {
    n: 'Saint Kitts and Nevis',
    c: '+1 (869)',
    m: '+1 (869)999-9999',
  },
  lc: {
    n: 'Saint Lucia',
    c: '+1 (758)',
    m: '+1 (758)999-9999',
  },
  vc: {
    n: 'Saint Vincent and the Grenadines',
    c: '+1 (784)',
    m: '+1 (784)999-9999',
  },
  ws: {
    n: 'Samoa',
    c: '+685',
    m: '+685-99-9999',
  },
  sm: {
    n: 'San Marino',
    c: '+378',
    m: '+378-9999-999999',
  },
  st: {
    n: 'Sao Tome and Principe (São Tomé e Príncipe)',
    c: '+239',
    m: '+239-99-99999',
  },
  sa: {
    n: 'Saudi Arabia (المملكة العربية السعودية)',
    c: '+966',
    m: '+966-9-9999-9999',
  },
  sn: {
    n: 'Senegal (Sénégal)',
    c: '+221',
    m: '+221-99-999-9999',
  },
  rs: {
    n: 'Serbia (Србија)',
    c: '+381',
    m: '+381-99-999-999',
  },
  sc: {
    n: 'Seychelles',
    c: '+248',
    m: '+248-9-999-999',
  },
  sl: {
    n: 'Sierra Leone',
    c: '+232',
    m: '+232-99-999999',
  },
  sg: {
    n: 'Singapore',
    c: '+65',
    m: '+65-9999-9999',
  },
  sk: {
    n: 'Slovakia (Slovensko)',
    c: '+421',
    m: '+421(999) 999-999',
  },
  si: {
    n: 'Slovenia (Slovenija)',
    c: '+386',
    m: '+386-99-999-999',
  },
  sb: {
    n: 'Solomon Islands',
    c: '+677',
    m: '+677-999-9999',
  },
  so: {
    n: 'Somalia (Soomaaliya)',
    c: '+252',
    m: '+252-99-999-999',
  },
  za: {
    n: 'South Africa',
    c: '+27',
    m: '+27-99-999-9999',
  },
  kr: {
    n: 'South Korea (대한민국)',
    c: '+82',
    m: '+82-99-9999-9999',
  },
  ss: {
    n: 'South Sudan (جنوب السودان)',
    c: '+211',
    m: '+211-99-999-9999',
  },
  es: {
    n: 'Spain (España)',
    c: '+34',
    m: '+34(999) 999-999',
  },
  lk: {
    n: 'Sri Lanka (ශ්‍රී ලංකාව)',
    c: '+94',
    m: '+94-99-999-9999',
  },
  sd: {
    n: 'Sudan (السودان)',
    c: '+249',
    m: '+249-99-999-9999',
  },
  sr: {
    n: 'Suriname',
    c: '+597',
    m: '+597-999-9999',
  },
  sz: {
    n: 'Swaziland',
    c: '+268',
    m: '+268-99-99-9999',
  },
  se: {
    n: 'Sweden (Sverige)',
    c: '+46',
    m: '+46-99-999-9999',
  },
  ch: {
    n: 'Switzerland (Schweiz)',
    c: '+41',
    m: '+41-99-999-9999',
  },
  sy: {
    n: 'Syria (سوريا)',
    c: '+963',
    m: '+963-99-9999-999',
  },
  tw: {
    n: 'Taiwan (台灣)',
    c: '+886',
    m: '+886-9999-9999',
  },
  tj: {
    n: 'Tajikistan',
    c: '+992',
    m: '+992-99-999-9999',
  },
  tz: {
    n: 'Tanzania',
    c: '+255',
    m: '+255-99-999-9999',
  },
  th: {
    n: 'Thailand (ไทย)',
    c: '+66',
    m: '+66-99-999-9999',
  },
  tg: {
    n: 'Togo',
    c: '+228',
    m: '+228-99-999-999',
  },
  to: {
    n: 'Tonga',
    c: '+676',
    m: '+676-99999',
  },
  tt: {
    n: 'Trinidad and Tobago',
    c: '+1 (868)',
    m: '+1 (868)999-9999',
  },
  tn: {
    n: 'Tunisia (تونس)',
    c: '+216',
    m: '+216-99-999-999',
  },
  tr: {
    n: 'Turkey (Türkiye)',
    c: '+90',
    m: '+90(999) 999-99999',
  },
  tm: {
    n: 'Turkmenistan',
    c: '+993',
    m: '+993-9-999-9999',
  },
  tv: {
    n: 'Tuvalu',
    c: '+688',
    m: '+688-999999',
  },
  ug: {
    n: 'Uganda',
    c: '+256',
    m: '+256(999) 999-999',
  },
  ua: {
    n: 'Ukraine (Україна)',
    c: '+380',
    m: '+380(99) 999-99-99',
  },
  ae: {
    n: 'United Arab Emirates (الإمارات العربية المتحدة)',
    c: '+971',
    m: '+971-99-999-9999',
  },
  gb: {
    n: 'United Kingdom',
    c: '+44',
    m: '+44-99-9999-99999',
  },
  us: {
    n: 'USA',
    c: '+1',
    m: '+1(999) 999-9999',
  },
  uy: {
    n: 'Uruguay',
    c: '+598',
    m: '+598-9-999-99-99',
  },
  uz: {
    n: 'Uzbekistan (Oʻzbekiston)',
    c: '+998',
    m: '+998-99-999-9999',
  },
  vu: {
    n: 'Vanuatu',
    c: '+678',
    m: '+678-99-99999',
  },
  va: {
    n: 'Vatican City (Città del Vaticano)',
    c: '+39',
    m: '+39-9-999-99999',
  },
  ve: {
    n: 'Venezuela',
    c: '+58',
    m: '+58(999) 999-9999',
  },
  vn: {
    n: 'Vietnam (Việt Nam)',
    c: '+84',
    m: '+84-99-9999-999',
  },
  ye: {
    n: 'Yemen (اليمن)',
    c: '+967',
    m: '+967-9-999-999',
  },
  zm: {
    n: 'Zambia',
    c: '+260',
    m: '+260-99-999-9999',
  },
  zw: {
    n: 'Zimbabwe',
    c: '+263',
    m: '+263-9-999999',
  },
}

export const flags = {
  ad: '-5px -5px',
  ae: '-33px -5px',
  af: '-61px -5px',
  ag: '-89px -5px',
  al: '-117px -5px',
  am: '-145px -5px',
  ao: '-173px -5px',
  ar: '-201px -5px',
  at: '-229px -5px',
  au: '-257px -5px',
  az: '-285px -5px',
  ba: '-313px -5px',
  bb: '-5px -28px',
  bd: '-33px -28px',
  be: '-61px -28px',
  bf: '-89px -28px',
  bg: '-117px -28px',
  bh: '-145px -28px',
  bi: '-173px -28px',
  bj: '-201px -28px',
  bm: '-229px -28px',
  bn: '-257px -28px',
  bo: '-285px -28px',
  br: '-313px -28px',
  bs: '-5px -51px',
  bt: '-33px -51px',
  bw: '-61px -51px',
  by: '-89px -51px',
  bz: '-117px -51px',
  ca: '-145px -51px',
  cd: '-173px -51px',
  cf: '-201px -51px',
  cg: '-229px -51px',
  ch: '-257px -51px',
  ci: '-285px -51px',
  ck: '-313px -51px',
  cl: '-5px -74px',
  cm: '-33px -74px',
  cn: '-61px -74px',
  co: '-89px -74px',
  cr: '-117px -74px',
  cu: '-145px -74px',
  cv: '-173px -74px',
  cy: '-201px -74px',
  cz: '-229px -74px',
  de: '-257px -74px',
  dj: '-285px -74px',
  dk: '-313px -74px',
  dm: '-5px -97px',
  do: '-33px -97px',
  dz: '-61px -97px',
  ec: '-89px -97px',
  ee: '-117px -97px',
  eg: '-145px -97px',
  eh: '-173px -97px',
  er: '-201px -97px',
  es: '-229px -97px',
  et: '-257px -97px',
  fi: '-285px -97px',
  fj: '-313px -97px',
  fm: '-5px -120px',
  fr: '-33px -120px',
  ga: '-61px -120px',
  gb: '-89px -120px',
  uk: '-89px -120px',
  gd: '-117px -120px',
  ge: '-145px -120px',
  gh: '-173px -120px',
  gm: '-201px -120px',
  gn: '-229px -120px',
  gq: '-257px -120px',
  gr: '-285px -120px',
  gt: '-313px -120px',
  gw: '-5px -143px',
  gy: '-33px -143px',
  hk: '-61px -143px',
  hn: '-89px -143px',
  hr: '-117px -143px',
  ht: '-145px -143px',
  hu: '-173px -143px',
  id: '-201px -143px',
  ie: '-229px -143px',
  il: '-257px -143px',
  in: '-285px -143px',
  iq: '-313px -143px',
  ir: '-5px -166px',
  is: '-33px -166px',
  it: '-61px -166px',
  jm: '-89px -166px',
  jo: '-117px -166px',
  jp: '-145px -166px',
  ke: '-173px -166px',
  kg: '-201px -166px',
  kh: '-229px -166px',
  ki: '-257px -166px',
  km: '-285px -166px',
  kn: '-313px -166px',
  kp: '-5px -189px',
  kr: '-33px -189px',
  ks: '-61px -189px',
  kw: '-89px -189px',
  kz: '-117px -189px',
  la: '-145px -189px',
  lb: '-173px -189px',
  lc: '-201px -189px',
  li: '-229px -189px',
  lk: '-257px -189px',
  lr: '-285px -189px',
  ls: '-313px -189px',
  lt: '-5px -212px',
  lu: '-33px -212px',
  lv: '-61px -212px',
  ly: '-89px -212px',
  ma: '-117px -212px',
  mc: '-145px -212px',
  md: '-173px -212px',
  me: '-201px -212px',
  mg: '-229px -212px',
  mh: '-257px -212px',
  mk: '-285px -212px',
  ml: '-313px -212px',
  mm: '-5px -235px',
  mn: '-33px -235px',
  mo: '-61px -235px',
  mr: '-89px -235px',
  mt: '-117px -235px',
  mu: '-145px -235px',
  mv: '-173px -235px',
  mw: '-201px -235px',
  mb: '-229px -235px',
  mx: '-229px -235px',
  my: '-257px -235px',
  mz: '-285px -235px',
  na: '-313px -235px',
  ne: '-5px -258px',
  ng: '-33px -258px',
  ni: '-61px -258px',
  nl: '-89px -258px',
  no: '-117px -258px',
  np: '-341px -5px',
  nr: '-145px -258px',
  nu: '-173px -258px',
  nz: '-201px -258px',
  om: '-229px -258px',
  pa: '-257px -258px',
  pe: '-285px -258px',
  pg: '-313px -258px',
  ph: '-5px -281px',
  pk: '-33px -281px',
  pl: '-61px -281px',
  ps: '-89px -281px',
  pt: '-117px -281px',
  pw: '-145px -281px',
  py: '-173px -281px',
  qa: '-201px -281px',
  ro: '-229px -281px',
  rs: '-257px -281px',
  ru: '-285px -281px',
  rw: '-313px -281px',
  sa: '-5px -304px',
  sb: '-33px -304px',
  sc: '-61px -304px',
  sd: '-89px -304px',
  se: '-117px -304px',
  sg: '-145px -304px',
  si: '-173px -304px',
  sk: '-201px -304px',
  sl: '-229px -304px',
  sm: '-257px -304px',
  sn: '-285px -304px',
  so: '-313px -304px',
  sr: '-5px -327px',
  ss: '-33px -327px',
  st: '-61px -327px',
  sv: '-89px -327px',
  sy: '-117px -327px',
  sz: '-145px -327px',
  td: '-173px -327px',
  tg: '-201px -327px',
  th: '-229px -327px',
  tj: '-257px -327px',
  tl: '-285px -327px',
  tm: '-313px -327px',
  tn: '-367px -5px',
  to: '-341px -28px',
  tr: '-341px -51px',
  tt: '-341px -74px',
  tv: '-341px -97px',
  tw: '-341px -120px',
  tz: '-341px -143px',
  ua: '-341px -166px',
  ug: '-341px -189px',
  us: '-341px -212px',
  uy: '-341px -235px',
  uz: '-341px -258px',
  va: '-341px -281px',
  vc: '-341px -304px',
  ve: '-341px -327px',
  vn: '-5px -350px',
  vu: '-33px -350px',
  ws: '-61px -350px',
  xk: '-89px -350px',
  ye: '-117px -350px',
  za: '-145px -350px',
  zm: '-173px -350px',
  zw: '-201px -350px',
}

export default [
  {
    mask: '+247-####',
    cc: 'AC',
    name_en: 'Ascension',
    desc_en: '',
    name_ru: 'Остров Вознесения',
    desc_ru: '',
  },
  {
    mask: '+376-###-###',
    cc: 'AD',
    name_en: 'Andorra',
    desc_en: '',
    name_ru: 'Андорра',
    desc_ru: '',
  },
  {
    mask: '+971-5#-###-####',
    cc: 'AE',
    name_en: 'United Arab Emirates',
    desc_en: 'mobile',
    name_ru: 'Объединенные Арабские Эмираты',
    desc_ru: 'мобильные',
  },
  {
    mask: '+971-#-###-####',
    cc: 'AE',
    name_en: 'United Arab Emirates',
    desc_en: '',
    name_ru: 'Объединенные Арабские Эмираты',
    desc_ru: '',
  },
  {
    mask: '+93-##-###-####',
    cc: 'AF',
    name_en: 'Afghanistan',
    desc_en: '',
    name_ru: 'Афганистан',
    desc_ru: '',
  },
  {
    mask: '+1 (268) ###-####',
    cc: 'AG',
    name_en: 'Antigua & Barbuda',
    desc_en: '',
    name_ru: 'Антигуа и Барбуда',
    desc_ru: '',
  },
  {
    mask: '+1 (264) ###-####',
    cc: 'AI',
    name_en: 'Anguilla',
    desc_en: '',
    name_ru: 'Ангилья',
    desc_ru: '',
  },
  {
    mask: '+355 (###) ###-##',
    cc: 'AL',
    name_en: 'Albania',
    desc_en: '',
    name_ru: 'Албания',
    desc_ru: '',
  },
  {
    mask: '+355 (###) ###-###',
    cc: 'AL',
    name_en: 'Albania',
    desc_en: '',
    name_ru: 'Албания',
    desc_ru: '',
  },
  {
    mask: '+383 ##-###-###',
    cc: 'XK',
    name_en: 'Kosovo',
    desc_en: '',
    name_ru: 'Косово',
    desc_ru: '',
  },
  {
    mask: '+374-##-###-###',
    cc: 'AM',
    name_en: 'Armenia',
    desc_en: '',
    name_ru: 'Армения',
    desc_ru: '',
  },
  {
    mask: '+599-###-####',
    cc: 'AN',
    name_en: 'Caribbean Netherlands',
    desc_en: '',
    name_ru: 'Карибские Нидерланды',
    desc_ru: '',
  },
  {
    mask: '+599-###-####',
    cc: 'AN',
    name_en: 'Netherlands Antilles',
    desc_en: '',
    name_ru: 'Нидерландские Антильские острова',
    desc_ru: '',
  },
  {
    mask: '+599-9###-####',
    cc: 'AN',
    name_en: 'Netherlands Antilles',
    desc_en: 'Curacao',
    name_ru: 'Нидерландские Антильские острова',
    desc_ru: 'Кюрасао',
  },
  {
    mask: '+244 (###) ###-###',
    cc: 'AO',
    name_en: 'Angola',
    desc_en: '',
    name_ru: 'Ангола',
    desc_ru: '',
  },
  {
    mask: '+672-1##-###',
    cc: 'AQ',
    name_en: 'Australian bases in Antarctica',
    desc_en: '',
    name_ru: 'Австралийская антарктическая база',
    desc_ru: '',
  },
  {
    mask: '+54 (##) ####-####',
    cc: 'AR',
    name_en: 'Argentina',
    desc_en: '',
    name_ru: 'Аргентина',
    desc_ru: '',
  },
  {
    mask: '+1 (684) ###-####',
    cc: 'AS',
    name_en: 'American Samoa',
    desc_en: '',
    name_ru: 'Американское Самоа',
    desc_ru: '',
  },
  {
    mask: '+43 (###) ###-####',
    cc: 'AT',
    name_en: 'Austria',
    desc_en: '',
    name_ru: 'Австрия',
    desc_ru: '',
  },
  {
    mask: '+61-#-####-####',
    cc: 'AU',
    name_en: 'Australia',
    desc_en: '',
    name_ru: 'Австралия',
    desc_ru: '',
  },
  {
    mask: '+297-###-####',
    cc: 'AW',
    name_en: 'Aruba',
    desc_en: '',
    name_ru: 'Аруба',
    desc_ru: '',
  },
  {
    mask: '+994-##-###-##-##',
    cc: 'AZ',
    name_en: 'Azerbaijan',
    desc_en: '',
    name_ru: 'Азербайджан',
    desc_ru: '',
  },
  {
    mask: '+387-##-####',
    cc: 'BA',
    name_en: 'Bosnia and Herzegovina',
    desc_en: '',
    name_ru: 'Босния и Герцеговина',
    desc_ru: '',
  },
  {
    mask: '+387-##-#####',
    cc: 'BA',
    name_en: 'Bosnia and Herzegovina',
    desc_en: '',
    name_ru: 'Босния и Герцеговина',
    desc_ru: '',
  },
  {
    mask: '+387-##-###-###',
    cc: 'BA',
    name_en: 'Bosnia and Herzegovina',
    desc_en: '',
    name_ru: 'Босния и Герцеговина',
    desc_ru: '',
  },
  {
    mask: '+387-###-###-##',
    cc: 'BA',
    name_en: 'Bosnia and Herzegovina',
    desc_en: '',
    name_ru: 'Босния и Герцеговина',
    desc_ru: '',
  },
  {
    mask: '+387-###-###-###',
    cc: 'BA',
    name_en: 'Bosnia and Herzegovina',
    desc_en: '',
    name_ru: 'Босния и Герцеговина',
    desc_ru: '',
  },
  {
    mask: '+1 (246) ###-####',
    cc: 'BB',
    name_en: 'Barbados',
    desc_en: '',
    name_ru: 'Барбадос',
    desc_ru: '',
  },
  {
    mask: '+880-##-###-###',
    cc: 'BD',
    name_en: 'Bangladesh',
    desc_en: '',
    name_ru: 'Бангладеш',
    desc_ru: '',
  },
  {
    mask: '+32 (###) ###-###',
    cc: 'BE',
    name_en: 'Belgium',
    desc_en: '',
    name_ru: 'Бельгия',
    desc_ru: '',
  },
  {
    mask: '+226-##-##-####',
    cc: 'BF',
    name_en: 'Burkina Faso',
    desc_en: '',
    name_ru: 'Буркина Фасо',
    desc_ru: '',
  },
  {
    mask: '+359 (###) ###-###',
    cc: 'BG',
    name_en: 'Bulgaria',
    desc_en: '',
    name_ru: 'Болгария',
    desc_ru: '',
  },
  {
    mask: '+973-####-####',
    cc: 'BH',
    name_en: 'Bahrain',
    desc_en: '',
    name_ru: 'Бахрейн',
    desc_ru: '',
  },
  {
    mask: '+257-##-##-####',
    cc: 'BI',
    name_en: 'Burundi',
    desc_en: '',
    name_ru: 'Бурунди',
    desc_ru: '',
  },
  {
    mask: '+229-##-##-####',
    cc: 'BJ',
    name_en: 'Benin',
    desc_en: '',
    name_ru: 'Бенин',
    desc_ru: '',
  },
  {
    mask: '+1 (441) ###-####',
    cc: 'BM',
    name_en: 'Bermuda',
    desc_en: '',
    name_ru: 'Бермудские острова',
    desc_ru: '',
  },
  {
    mask: '+673-###-####',
    cc: 'BN',
    name_en: 'Brunei Darussalam',
    desc_en: '',
    name_ru: 'Бруней-Даруссалам',
    desc_ru: '',
  },
  {
    mask: '+591-#-###-####',
    cc: 'BO',
    name_en: 'Bolivia',
    desc_en: '',
    name_ru: 'Боливия',
    desc_ru: '',
  },
  {
    mask: '+55 (##) ####-####',
    cc: 'BR',
    name_en: 'Brazil',
    desc_en: '',
    name_ru: 'Бразилия',
    desc_ru: '',
  },
  {
    mask: '+55 (##) 7###-####',
    cc: 'BR',
    name_en: 'Brazil',
    desc_en: 'mobile',
    name_ru: 'Бразилия',
    desc_ru: 'мобильные',
  },
  {
    mask: '+55 (##) 9####-####',
    cc: 'BR',
    name_en: 'Brazil',
    desc_en: 'mobile',
    name_ru: 'Бразилия',
    desc_ru: 'мобильные',
  },
  {
    mask: '+1 (242) ###-####',
    cc: 'BS',
    name_en: 'Bahamas',
    desc_en: '',
    name_ru: 'Багамские Острова',
    desc_ru: '',
  },
  {
    mask: '+975-17-###-###',
    cc: 'BT',
    name_en: 'Bhutan',
    desc_en: '',
    name_ru: 'Бутан',
    desc_ru: '',
  },
  {
    mask: '+975-#-###-###',
    cc: 'BT',
    name_en: 'Bhutan',
    desc_en: '',
    name_ru: 'Бутан',
    desc_ru: '',
  },
  {
    mask: '+267-##-###-###',
    cc: 'BW',
    name_en: 'Botswana',
    desc_en: '',
    name_ru: 'Ботсвана',
    desc_ru: '',
  },
  {
    mask: '+375 (##) ###-##-##',
    cc: 'BY',
    name_en: 'Belarus',
    desc_en: '',
    name_ru: 'Беларусь  (Белоруссия) ',
    desc_ru: '',
  },
  {
    mask: '+501-###-####',
    cc: 'BZ',
    name_en: 'Belize',
    desc_en: '',
    name_ru: 'Белиз',
    desc_ru: '',
  },
  {
    mask: '+243 (###) ###-###',
    cc: 'CD',
    name_en: 'Dem. Rep. Congo',
    desc_en: '',
    name_ru: 'Дем. Респ. Конго  (Киншаса) ',
    desc_ru: '',
  },
  {
    mask: '+236-##-##-####',
    cc: 'CF',
    name_en: 'Central African Republic',
    desc_en: '',
    name_ru: 'Центральноафриканская Республика',
    desc_ru: '',
  },
  {
    mask: '+242-##-###-####',
    cc: 'CG',
    name_en: 'Congo  (Brazzaville) ',
    desc_en: '',
    name_ru: 'Конго  (Браззавиль) ',
    desc_ru: '',
  },
  {
    mask: '+41-##-###-####',
    cc: 'CH',
    name_en: 'Switzerland',
    desc_en: '',
    name_ru: 'Швейцария',
    desc_ru: '',
  },
  {
    mask: '+225-##-###-###',
    cc: 'CI',
    name_en: 'Cote d’Ivoire  (Ivory Coast) ',
    desc_en: '',
    name_ru: 'Кот-д’Ивуар',
    desc_ru: '',
  },
  {
    mask: '+682-##-###',
    cc: 'CK',
    name_en: 'Cook Islands',
    desc_en: '',
    name_ru: 'Острова Кука',
    desc_ru: '',
  },
  {
    mask: '+56-#-####-####',
    cc: 'CL',
    name_en: 'Chile',
    desc_en: '',
    name_ru: 'Чили',
    desc_ru: '',
  },
  {
    mask: '+56-###-####-###',
    cc: 'CL',
    name_en: 'Chile',
    desc_en: '',
    name_ru: 'Чили',
    desc_ru: '',
  },
  {
    mask: '+237-####-####',
    cc: 'CM',
    name_en: 'Cameroon',
    desc_en: '',
    name_ru: 'Камерун',
    desc_ru: '',
  },
  {
    mask: '+237-####-#####',
    cc: 'CM',
    name_en: 'Cameroon',
    desc_en: '',
    name_ru: 'Камерун',
    desc_ru: '',
  },
  {
    mask: '+86 (###) ####-####',
    cc: 'CN',
    name_en: 'China  (PRC) ',
    desc_en: '',
    name_ru: 'Китайская Н.Р.',
    desc_ru: '',
  },
  {
    mask: '+86 (###) ####-###',
    cc: 'CN',
    name_en: 'China  (PRC) ',
    desc_en: '',
    name_ru: 'Китайская Н.Р.',
    desc_ru: '',
  },
  {
    mask: '+86-##-#####-#####',
    cc: 'CN',
    name_en: 'China  (PRC) ',
    desc_en: '',
    name_ru: 'Китайская Н.Р.',
    desc_ru: '',
  },
  {
    mask: '+57 (###) ###-####',
    cc: 'CO',
    name_en: 'Colombia',
    desc_en: '',
    name_ru: 'Колумбия',
    desc_ru: '',
  },
  {
    mask: '+506-####-####',
    cc: 'CR',
    name_en: 'Costa Rica',
    desc_en: '',
    name_ru: 'Коста-Рика',
    desc_ru: '',
  },
  {
    mask: '+53-#-###-####',
    cc: 'CU',
    name_en: 'Cuba',
    desc_en: '',
    name_ru: 'Куба',
    desc_ru: '',
  },
  {
    mask: '+238 (###) ##-##',
    cc: 'CV',
    name_en: 'Cape Verde',
    desc_en: '',
    name_ru: 'Кабо-Верде',
    desc_ru: '',
  },
  {
    mask: '+599-###-####',
    cc: 'CW',
    name_en: 'Curacao',
    desc_en: '',
    name_ru: 'Кюрасао',
    desc_ru: '',
  },
  {
    mask: '+357-##-###-###',
    cc: 'CY',
    name_en: 'Cyprus',
    desc_en: '',
    name_ru: 'Кипр',
    desc_ru: '',
  },
  {
    mask: '+420 (###) ###-###',
    cc: 'CZ',
    name_en: 'Czech Republic',
    desc_en: '',
    name_ru: 'Чехия',
    desc_ru: '',
  },
  {
    mask: '+49 (####) ###-###',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49 (####) ###-####',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49 (###) ###-####',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49 (###) ##-####',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49 (###) ##-###',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49 (###) ##-##',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+49-###-###',
    cc: 'DE',
    name_en: 'Germany',
    desc_en: '',
    name_ru: 'Германия',
    desc_ru: '',
  },
  {
    mask: '+253-##-##-##-##',
    cc: 'DJ',
    name_en: 'Djibouti',
    desc_en: '',
    name_ru: 'Джибути',
    desc_ru: '',
  },
  {
    mask: '+45-##-##-##-##',
    cc: 'DK',
    name_en: 'Denmark',
    desc_en: '',
    name_ru: 'Дания',
    desc_ru: '',
  },
  {
    mask: '+1 (767) ###-####',
    cc: 'DM',
    name_en: 'Dominica',
    desc_en: '',
    name_ru: 'Доминика',
    desc_ru: '',
  },
  {
    mask: '+1 (809) ###-####',
    cc: 'DO',
    name_en: 'Dominican Republic',
    desc_en: '',
    name_ru: 'Доминиканская Республика',
    desc_ru: '',
  },
  {
    mask: '+1 (829) ###-####',
    cc: 'DO',
    name_en: 'Dominican Republic',
    desc_en: '',
    name_ru: 'Доминиканская Республика',
    desc_ru: '',
  },
  {
    mask: '+1 (849) ###-####',
    cc: 'DO',
    name_en: 'Dominican Republic',
    desc_en: '',
    name_ru: 'Доминиканская Республика',
    desc_ru: '',
  },
  {
    mask: '+213-##-###-####',
    cc: 'DZ',
    name_en: 'Algeria',
    desc_en: '',
    name_ru: 'Алжир',
    desc_ru: '',
  },
  {
    mask: '+593-#-###-####',
    cc: 'EC',
    name_en: 'Ecuador',
    desc_en: '',
    name_ru: 'Эквадор',
    desc_ru: '',
  },
  {
    mask: '+593-##-###-####',
    cc: 'EC',
    name_en: 'Ecuador ',
    desc_en: 'mobile',
    name_ru: 'Эквадор ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+372-###-####',
    cc: 'EE',
    name_en: 'Estonia',
    desc_en: '',
    name_ru: 'Эстония',
    desc_ru: '',
  },
  {
    mask: '+372-####-####',
    cc: 'EE',
    name_en: 'Estonia ',
    desc_en: 'mobile',
    name_ru: 'Эстония ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+20 (###) ###-####',
    cc: 'EG',
    name_en: 'Egypt',
    desc_en: '',
    name_ru: 'Египет',
    desc_ru: '',
  },
  {
    mask: '+291-#-###-###',
    cc: 'ER',
    name_en: 'Eritrea',
    desc_en: '',
    name_ru: 'Эритрея',
    desc_ru: '',
  },
  {
    mask: '+34 (###) ###-###',
    cc: 'ES',
    name_en: 'Spain',
    desc_en: '',
    name_ru: 'Испания',
    desc_ru: '',
  },
  {
    mask: '+251-##-###-####',
    cc: 'ET',
    name_en: 'Ethiopia',
    desc_en: '',
    name_ru: 'Эфиопия',
    desc_ru: '',
  },
  {
    mask: '+358 (###) ###-##-##',
    cc: 'FI',
    name_en: 'Finland',
    desc_en: '',
    name_ru: 'Финляндия',
    desc_ru: '',
  },
  {
    mask: '+679-##-#####',
    cc: 'FJ',
    name_en: 'Fiji',
    desc_en: '',
    name_ru: 'Фиджи',
    desc_ru: '',
  },
  {
    mask: '+500-#####',
    cc: 'FK',
    name_en: 'Falkland Islands',
    desc_en: '',
    name_ru: 'Фолклендские острова',
    desc_ru: '',
  },
  {
    mask: '+691-###-####',
    cc: 'FM',
    name_en: 'F.S. Micronesia',
    desc_en: '',
    name_ru: 'Ф.Ш. Микронезии',
    desc_ru: '',
  },
  {
    mask: '+298-###-###',
    cc: 'FO',
    name_en: 'Faroe Islands',
    desc_en: '',
    name_ru: 'Фарерские острова',
    desc_ru: '',
  },
  {
    mask: '+262-#####-####',
    cc: 'FR',
    name_en: 'Mayotte',
    desc_en: '',
    name_ru: 'Майотта',
    desc_ru: '',
  },
  {
    mask: '+33 7 ## ## ## ##',
    cc: 'FR',
    name_en: 'France',
    desc_en: '',
    name_ru: 'Франция',
    desc_ru: '',
  },
  {
    mask: '+33 ## ## ## ## ##',
    cc: 'FR',
    name_en: 'France',
    desc_en: '',
    name_ru: 'Франция',
    desc_ru: '',
  },
  {
    mask: '+33 7-##-##-##-#',
    cc: 'FR',
    name_en: 'France',
    desc_en: '',
    name_ru: 'Франция',
    desc_ru: '',
  },
  {
    mask: '+508-##-####',
    cc: 'FR',
    name_en: 'St Pierre & Miquelon',
    desc_en: '',
    name_ru: 'Сен-Пьер и Микелон',
    desc_ru: '',
  },
  {
    mask: '+590 (###) ###-###',
    cc: 'FR',
    name_en: 'Guadeloupe',
    desc_en: '',
    name_ru: 'Гваделупа',
    desc_ru: '',
  },
  {
    mask: '+241-#-##-##-##',
    cc: 'GA',
    name_en: 'Gabon',
    desc_en: '',
    name_ru: 'Габон',
    desc_ru: '',
  },
  {
    mask: '+1 (473) ###-####',
    cc: 'GD',
    name_en: 'Grenada',
    desc_en: '',
    name_ru: 'Гренада',
    desc_ru: '',
  },
  {
    mask: '+995 (###) ###-###',
    cc: 'GE',
    name_en: 'Rep. of Georgia',
    desc_en: '',
    name_ru: 'Грузия',
    desc_ru: '',
  },
  {
    mask: '+594-#####-####',
    cc: 'GF',
    name_en: 'Guiana  (French) ',
    desc_en: '',
    name_ru: 'Фр. Гвиана',
    desc_ru: '',
  },
  {
    mask: '+233 (###) ###-###',
    cc: 'GH',
    name_en: 'Ghana',
    desc_en: '',
    name_ru: 'Гана',
    desc_ru: '',
  },
  {
    mask: '+350-###-#####',
    cc: 'GI',
    name_en: 'Gibraltar',
    desc_en: '',
    name_ru: 'Гибралтар',
    desc_ru: '',
  },
  {
    mask: '+299-##-##-##',
    cc: 'GL',
    name_en: 'Greenland',
    desc_en: '',
    name_ru: 'Гренландия',
    desc_ru: '',
  },
  {
    mask: '+220 (###) ##-##',
    cc: 'GM',
    name_en: 'Gambia',
    desc_en: '',
    name_ru: 'Гамбия',
    desc_ru: '',
  },
  {
    mask: '+224-##-###-###',
    cc: 'GN',
    name_en: 'Guinea',
    desc_en: '',
    name_ru: 'Гвинея',
    desc_ru: '',
  },
  {
    mask: '+240-##-###-####',
    cc: 'GQ',
    name_en: 'Equatorial Guinea',
    desc_en: '',
    name_ru: 'Экваториальная Гвинея',
    desc_ru: '',
  },
  {
    mask: '+30 (###) ###-####',
    cc: 'GR',
    name_en: 'Greece',
    desc_en: '',
    name_ru: 'Греция',
    desc_ru: '',
  },
  {
    mask: '+502-####-####',
    cc: 'GT',
    name_en: 'Guatemala',
    desc_en: '',
    name_ru: 'Гватемала',
    desc_ru: '',
  },
  {
    mask: '+1 (671) ###-####',
    cc: 'GU',
    name_en: 'Guam',
    desc_en: '',
    name_ru: 'Гуам',
    desc_ru: '',
  },
  {
    mask: '+245-#-######',
    cc: 'GW',
    name_en: 'Guinea-Bissau',
    desc_en: '',
    name_ru: 'Гвинея-Бисау',
    desc_ru: '',
  },
  {
    mask: '+592-###-####',
    cc: 'GY',
    name_en: 'Guyana',
    desc_en: '',
    name_ru: 'Гайана',
    desc_ru: '',
  },
  {
    mask: '+852-####-####',
    cc: 'HK',
    name_en: 'Hong Kong',
    desc_en: '',
    name_ru: 'Гонконг',
    desc_ru: '',
  },
  {
    mask: '+504-####-####',
    cc: 'HN',
    name_en: 'Honduras',
    desc_en: '',
    name_ru: 'Гондурас',
    desc_ru: '',
  },
  {
    mask: '+385-##-###-###',
    cc: 'HR',
    name_en: 'Croatia',
    desc_en: '',
    name_ru: 'Хорватия',
    desc_ru: '',
  },
  {
    mask: '+385-##-###-####',
    cc: 'HR',
    name_en: 'Croatia',
    desc_en: '',
    name_ru: 'Хорватия',
    desc_ru: '',
  },
  {
    mask: '+509-##-##-####',
    cc: 'HT',
    name_en: 'Haiti',
    desc_en: '',
    name_ru: 'Гаити',
    desc_ru: '',
  },
  {
    mask: '+36 ####-####',
    cc: 'HU',
    name_en: 'Hungary',
    desc_en: '',
    name_ru: 'Венгрия',
    desc_ru: '',
  },
  {
    mask: '+36 (###) ###-###',
    cc: 'HU',
    name_en: 'Hungary',
    desc_en: '',
    name_ru: 'Венгрия',
    desc_ru: '',
  },
  {
    mask: '+62 (8##) ###-####',
    cc: 'ID',
    name_en: 'Indonesia ',
    desc_en: 'mobile',
    name_ru: 'Индонезия ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+62-##-###-##',
    cc: 'ID',
    name_en: 'Indonesia',
    desc_en: '',
    name_ru: 'Индонезия',
    desc_ru: '',
  },
  {
    mask: '+62-##-###-###',
    cc: 'ID',
    name_en: 'Indonesia',
    desc_en: '',
    name_ru: 'Индонезия',
    desc_ru: '',
  },
  {
    mask: '+62-##-###-####',
    cc: 'ID',
    name_en: 'Indonesia',
    desc_en: '',
    name_ru: 'Индонезия',
    desc_ru: '',
  },
  {
    mask: '+62 (8##) ###-###',
    cc: 'ID',
    name_en: 'Indonesia ',
    desc_en: 'mobile',
    name_ru: 'Индонезия ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+62 (8##) ###-##-###',
    cc: 'ID',
    name_en: 'Indonesia ',
    desc_en: 'mobile',
    name_ru: 'Индонезия ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+353 (###) ###-###',
    cc: 'IE',
    name_en: 'Ireland',
    desc_en: '',
    name_ru: 'Ирландия',
    desc_ru: '',
  },
  {
    mask: '+972-5#-###-####',
    cc: 'IL',
    name_en: 'Israel ',
    desc_en: 'mobile',
    name_ru: 'Израиль ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+972-#-###-####',
    cc: 'IL',
    name_en: 'Israel',
    desc_en: '',
    name_ru: 'Израиль',
    desc_ru: '',
  },
  {
    mask: '+91 (####) ###-###',
    cc: 'IN',
    name_en: 'India',
    desc_en: '',
    name_ru: 'Индия',
    desc_ru: '',
  },
  {
    mask: '+91-###-###-###-##',
    cc: 'IN',
    name_en: 'India',
    desc_en: '',
    name_ru: 'Индия',
    desc_ru: '',
  },
  {
    mask: '+246-###-####',
    cc: 'IO',
    name_en: 'Diego Garcia',
    desc_en: '',
    name_ru: 'Диего-Гарсия',
    desc_ru: '',
  },
  {
    mask: '+964 (###) ###-####',
    cc: 'IQ',
    name_en: 'Iraq',
    desc_en: '',
    name_ru: 'Ирак',
    desc_ru: '',
  },
  {
    mask: '+98 (###) ###-####',
    cc: 'IR',
    name_en: 'Iran',
    desc_en: '',
    name_ru: 'Иран',
    desc_ru: '',
  },
  {
    mask: '+354-###-####',
    cc: 'IS',
    name_en: 'Iceland',
    desc_en: '',
    name_ru: 'Исландия',
    desc_ru: '',
  },
  {
    mask: '+39 (###) ####-###',
    cc: 'IT',
    name_en: 'Italy',
    desc_en: '',
    name_ru: 'Италия',
    desc_ru: '',
  },
  {
    mask: '+1 (876) ###-####',
    cc: 'JM',
    name_en: 'Jamaica',
    desc_en: '',
    name_ru: 'Ямайка',
    desc_ru: '',
  },
  {
    mask: '+962-#-####-####',
    cc: 'JO',
    name_en: 'Jordan',
    desc_en: '',
    name_ru: 'Иордания',
    desc_ru: '',
  },
  {
    mask: '+81-##-####-####',
    cc: 'JP',
    name_en: 'Japan ',
    desc_en: 'mobile',
    name_ru: 'Япония ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+81 (###) ###-###',
    cc: 'JP',
    name_en: 'Japan',
    desc_en: '',
    name_ru: 'Япония',
    desc_ru: '',
  },
  {
    mask: '+254-##-###-###',
    cc: 'KE',
    name_en: 'Kenya',
    desc_en: '',
    name_ru: 'Кения',
    desc_ru: '',
  },
  {
    mask: '+254-###-######',
    cc: 'KE',
    name_en: 'Kenya',
    desc_en: '',
    name_ru: 'Кения',
    desc_ru: '',
  },
  {
    mask: '+996 (###) ###-###',
    cc: 'KG',
    name_en: 'Kyrgyzstan',
    desc_en: '',
    name_ru: 'Киргизия',
    desc_ru: '',
  },
  {
    mask: '+855-##-###-###',
    cc: 'KH',
    name_en: 'Cambodia',
    desc_en: '',
    name_ru: 'Камбоджа',
    desc_ru: '',
  },
  {
    mask: '+855-##-###-####',
    cc: 'KH',
    name_en: 'Cambodia',
    desc_en: '',
    name_ru: 'Камбоджа',
    desc_ru: '',
  },
  {
    mask: '+686-##-###',
    cc: 'KI',
    name_en: 'Kiribati',
    desc_en: '',
    name_ru: 'Кирибати',
    desc_ru: '',
  },
  {
    mask: '+269-##-#####',
    cc: 'KM',
    name_en: 'Comoros',
    desc_en: '',
    name_ru: 'Коморы',
    desc_ru: '',
  },
  {
    mask: '+1 (869) ###-####',
    cc: 'KN',
    name_en: 'Saint Kitts & Nevis',
    desc_en: '',
    name_ru: 'Сент-Китс и Невис',
    desc_ru: '',
  },
  {
    mask: '+850-191-###-####',
    cc: 'KP',
    name_en: 'DPR Korea  (North)  ',
    desc_en: 'mobile',
    name_ru: 'Корейская НДР ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+850-##-###-###',
    cc: 'KP',
    name_en: 'DPR Korea  (North) ',
    desc_en: '',
    name_ru: 'Корейская НДР',
    desc_ru: '',
  },
  {
    mask: '+850-###-####-###',
    cc: 'KP',
    name_en: 'DPR Korea  (North) ',
    desc_en: '',
    name_ru: 'Корейская НДР',
    desc_ru: '',
  },
  {
    mask: '+850-###-###',
    cc: 'KP',
    name_en: 'DPR Korea  (North) ',
    desc_en: '',
    name_ru: 'Корейская НДР',
    desc_ru: '',
  },
  {
    mask: '+850-####-####',
    cc: 'KP',
    name_en: 'DPR Korea  (North) ',
    desc_en: '',
    name_ru: 'Корейская НДР',
    desc_ru: '',
  },
  {
    mask: '+850-####-#############',
    cc: 'KP',
    name_en: 'DPR Korea  (North) ',
    desc_en: '',
    name_ru: 'Корейская НДР',
    desc_ru: '',
  },
  {
    mask: '+82-##-###-####',
    cc: 'KR',
    name_en: 'Korea  (South) ',
    desc_en: '',
    name_ru: 'Респ. Корея',
    desc_ru: '',
  },
  {
    mask: '+965-####-####',
    cc: 'KW',
    name_en: 'Kuwait',
    desc_en: '',
    name_ru: 'Кувейт',
    desc_ru: '',
  },
  {
    mask: '+1 (345) ###-####',
    cc: 'KY',
    name_en: 'Cayman Islands',
    desc_en: '',
    name_ru: 'Каймановы острова',
    desc_ru: '',
  },
  {
    mask: '+7 (6##) ###-##-##',
    cc: 'KZ',
    name_en: 'Kazakhstan',
    desc_en: '',
    name_ru: 'Казахстан',
    desc_ru: '',
  },
  {
    mask: '+7 (7##) ###-##-##',
    cc: 'KZ',
    name_en: 'Kazakhstan',
    desc_en: '',
    name_ru: 'Казахстан',
    desc_ru: '',
  },
  {
    mask: '+856 (20##) ###-###',
    cc: 'LA',
    name_en: 'Laos ',
    desc_en: 'mobile',
    name_ru: 'Лаос ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+856-##-###-###',
    cc: 'LA',
    name_en: 'Laos',
    desc_en: '',
    name_ru: 'Лаос',
    desc_ru: '',
  },
  {
    mask: '+961-##-###-###',
    cc: 'LB',
    name_en: 'Lebanon ',
    desc_en: 'mobile',
    name_ru: 'Ливан ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+961-#-###-###',
    cc: 'LB',
    name_en: 'Lebanon',
    desc_en: '',
    name_ru: 'Ливан',
    desc_ru: '',
  },
  {
    mask: '+1 (758) ###-####',
    cc: 'LC',
    name_en: 'Saint Lucia',
    desc_en: '',
    name_ru: 'Сент-Люсия',
    desc_ru: '',
  },
  {
    mask: '+423 (###) ###-####',
    cc: 'LI',
    name_en: 'Liechtenstein',
    desc_en: '',
    name_ru: 'Лихтенштейн',
    desc_ru: '',
  },
  {
    mask: '+94-##-###-####',
    cc: 'LK',
    name_en: 'Sri Lanka',
    desc_en: '',
    name_ru: 'Шри-Ланка',
    desc_ru: '',
  },
  {
    mask: '+231-##-###-###',
    cc: 'LR',
    name_en: 'Liberia',
    desc_en: '',
    name_ru: 'Либерия',
    desc_ru: '',
  },
  {
    mask: '+266-#-###-####',
    cc: 'LS',
    name_en: 'Lesotho',
    desc_en: '',
    name_ru: 'Лесото',
    desc_ru: '',
  },
  {
    mask: '+370 (###) ##-###',
    cc: 'LT',
    name_en: 'Lithuania',
    desc_en: '',
    name_ru: 'Литва',
    desc_ru: '',
  },
  {
    mask: '+352 (###) ###-###',
    cc: 'LU',
    name_en: 'Luxembourg',
    desc_en: '',
    name_ru: 'Люксембург',
    desc_ru: '',
  },
  {
    mask: '+371-##-###-###',
    cc: 'LV',
    name_en: 'Latvia',
    desc_en: '',
    name_ru: 'Латвия',
    desc_ru: '',
  },
  {
    mask: '+218-##-###-###',
    cc: 'LY',
    name_en: 'Libya',
    desc_en: '',
    name_ru: 'Ливия',
    desc_ru: '',
  },
  {
    mask: '+218-###-###-###',
    cc: 'LY',
    name_en: 'Libya',
    desc_en: '',
    name_ru: 'Ливия',
    desc_ru: '',
  },
  {
    mask: '+218-21-###-####',
    cc: 'LY',
    name_en: 'Libya',
    desc_en: 'Tripoli',
    name_ru: 'Ливия',
    desc_ru: 'Триполи',
  },
  {
    mask: '+212-##-####-###',
    cc: 'MA',
    name_en: 'Morocco',
    desc_en: '',
    name_ru: 'Марокко',
    desc_ru: '',
  },
  {
    mask: '+377 (###) ###-###',
    cc: 'MC',
    name_en: 'Monaco',
    desc_en: '',
    name_ru: 'Монако',
    desc_ru: '',
  },
  {
    mask: '+377-##-###-###',
    cc: 'MC',
    name_en: 'Monaco',
    desc_en: '',
    name_ru: 'Монако',
    desc_ru: '',
  },
  {
    mask: '+373-####-####',
    cc: 'MD',
    name_en: 'Moldova',
    desc_en: '',
    name_ru: 'Молдова',
    desc_ru: '',
  },
  {
    mask: '+382-##-###-###',
    cc: 'ME',
    name_en: 'Montenegro',
    desc_en: '',
    name_ru: 'Черногория',
    desc_ru: '',
  },
  {
    mask: '+261-##-##-#####',
    cc: 'MG',
    name_en: 'Madagascar',
    desc_en: '',
    name_ru: 'Мадагаскар',
    desc_ru: '',
  },
  {
    mask: '+692-###-####',
    cc: 'MH',
    name_en: 'Marshall Islands',
    desc_en: '',
    name_ru: 'Маршалловы Острова',
    desc_ru: '',
  },
  {
    mask: '+389-##-###-###',
    cc: 'MK',
    name_en: 'Republic of Macedonia',
    desc_en: '',
    name_ru: 'Респ. Македония',
    desc_ru: '',
  },
  {
    mask: '+223-##-##-####',
    cc: 'ML',
    name_en: 'Mali',
    desc_en: '',
    name_ru: 'Мали',
    desc_ru: '',
  },
  {
    mask: '+95-##-###-###',
    cc: 'MM',
    name_en: 'Burma  (Myanmar) ',
    desc_en: '',
    name_ru: 'Бирма  (Мьянма) ',
    desc_ru: '',
  },
  {
    mask: '+95-#-###-###',
    cc: 'MM',
    name_en: 'Burma  (Myanmar) ',
    desc_en: '',
    name_ru: 'Бирма  (Мьянма) ',
    desc_ru: '',
  },
  {
    mask: '+95-###-###',
    cc: 'MM',
    name_en: 'Burma  (Myanmar) ',
    desc_en: '',
    name_ru: 'Бирма  (Мьянма) ',
    desc_ru: '',
  },
  {
    mask: '+976-##-##-####',
    cc: 'MN',
    name_en: 'Mongolia',
    desc_en: '',
    name_ru: 'Монголия',
    desc_ru: '',
  },
  {
    mask: '+853-####-####',
    cc: 'MO',
    name_en: 'Macau',
    desc_en: '',
    name_ru: 'Макао',
    desc_ru: '',
  },
  {
    mask: '+1 (670) ###-####',
    cc: 'MP',
    name_en: 'Northern Mariana Islands',
    desc_en: '',
    name_ru: 'Северные Марианские острова Сайпан',
    desc_ru: '',
  },
  {
    mask: '+596 (###) ##-##-##',
    cc: 'MQ',
    name_en: 'Martinique',
    desc_en: '',
    name_ru: 'Мартиника',
    desc_ru: '',
  },
  {
    mask: '+222-##-##-####',
    cc: 'MR',
    name_en: 'Mauritania',
    desc_en: '',
    name_ru: 'Мавритания',
    desc_ru: '',
  },
  {
    mask: '+1 (664) ###-####',
    cc: 'MS',
    name_en: 'Montserrat',
    desc_en: '',
    name_ru: 'Монтсеррат',
    desc_ru: '',
  },
  {
    mask: '+356-####-####',
    cc: 'MT',
    name_en: 'Malta',
    desc_en: '',
    name_ru: 'Мальта',
    desc_ru: '',
  },
  {
    mask: '+230-###-####',
    cc: 'MU',
    name_en: 'Mauritius',
    desc_en: '',
    name_ru: 'Маврикий',
    desc_ru: '',
  },
  {
    mask: '+230-###-###-##',
    cc: 'MU',
    name_en: 'Mauritius',
    desc_en: '',
    name_ru: 'Маврикий',
    desc_ru: '',
  },
  {
    mask: '+960-###-####',
    cc: 'MV',
    name_en: 'Maldives',
    desc_en: '',
    name_ru: 'Мальдивские острова',
    desc_ru: '',
  },
  {
    mask: '+265-1-###-###',
    cc: 'MW',
    name_en: 'Malawi',
    desc_en: 'Telecom Ltd',
    name_ru: 'Малави',
    desc_ru: 'Telecom Ltd',
  },
  {
    mask: '+265-#-####-####',
    cc: 'MW',
    name_en: 'Malawi',
    desc_en: '',
    name_ru: 'Малави',
    desc_ru: '',
  },
  {
    mask: '+52 (###) ###-####',
    cc: 'MX',
    name_en: 'Mexico',
    desc_en: '',
    name_ru: 'Мексика',
    desc_ru: '',
  },
  {
    mask: '+52-##-##-####',
    cc: 'MX',
    name_en: 'Mexico',
    desc_en: '',
    name_ru: 'Мексика',
    desc_ru: '',
  },
  {
    mask: '+52 (###) ###-####-#',
    cc: 'MX',
    name_en: 'Mexico',
    desc_en: '',
    name_ru: 'Мексика',
    desc_ru: '',
  },
  {
    mask: '+60-#-###-###',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+60-##-###-###',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+60-##-###-####',
    cc: 'MY',
    name_en: 'Malaysia ',
    desc_en: 'mobile',
    name_ru: 'Малайзия ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+60-#-####-####',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+60 (###) ###-###',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+60-##-####-####',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+60-##-###-###-###',
    cc: 'MY',
    name_en: 'Malaysia',
    desc_en: '',
    name_ru: 'Малайзия',
    desc_ru: '',
  },
  {
    mask: '+258-##-#####',
    cc: 'MZ',
    name_en: 'Mozambique',
    desc_en: '',
    name_ru: 'Мозамбик',
    desc_ru: '',
  },
  {
    mask: '+258-##-###-###',
    cc: 'MZ',
    name_en: 'Mozambique',
    desc_en: '',
    name_ru: 'Мозамбик',
    desc_ru: '',
  },
  {
    mask: '+258-###-###-###',
    cc: 'MZ',
    name_en: 'Mozambique',
    desc_en: '',
    name_ru: 'Мозамбик',
    desc_ru: '',
  },
  {
    mask: '+264-##-###-####',
    cc: 'NA',
    name_en: 'Namibia',
    desc_en: '',
    name_ru: 'Намибия',
    desc_ru: '',
  },
  {
    mask: '+687-##-####',
    cc: 'NC',
    name_en: 'New Caledonia',
    desc_en: '',
    name_ru: 'Новая Каледония',
    desc_ru: '',
  },
  {
    mask: '+227-##-##-####',
    cc: 'NE',
    name_en: 'Niger',
    desc_en: '',
    name_ru: 'Нигер',
    desc_ru: '',
  },
  {
    mask: '+672-3##-###',
    cc: 'NF',
    name_en: 'Norfolk Island',
    desc_en: '',
    name_ru: 'Норфолк  (остров) ',
    desc_ru: '',
  },
  {
    mask: '+234-##-###-##',
    cc: 'NG',
    name_en: 'Nigeria',
    desc_en: '',
    name_ru: 'Нигерия',
    desc_ru: '',
  },
  {
    mask: '+234-##-###-###',
    cc: 'NG',
    name_en: 'Nigeria',
    desc_en: '',
    name_ru: 'Нигерия',
    desc_ru: '',
  },
  {
    mask: '+234-#-###-#####',
    cc: 'NG',
    name_en: 'Nigeria',
    desc_en: '',
    name_ru: 'Нигерия',
    desc_ru: '',
  },
  {
    mask: '+234 (###) ###-####',
    cc: 'NG',
    name_en: 'Nigeria',
    desc_en: '',
    name_ru: 'Нигерия',
    desc_ru: '',
  },
  {
    mask: '+505-####-####',
    cc: 'NI',
    name_en: 'Nicaragua',
    desc_en: '',
    name_ru: 'Никарагуа',
    desc_ru: '',
  },
  {
    mask: '+31-##-###-####',
    cc: 'NL',
    name_en: 'Netherlands',
    desc_en: '',
    name_ru: 'Нидерланды',
    desc_ru: '',
  },
  {
    mask: '+47 (###) ##-###',
    cc: 'NO',
    name_en: 'Norway',
    desc_en: '',
    name_ru: 'Норвегия',
    desc_ru: '',
  },
  {
    mask: '+977-##-###-###',
    cc: 'NP',
    name_en: 'Nepal',
    desc_en: '',
    name_ru: 'Непал',
    desc_ru: '',
  },
  {
    mask: '+674-###-####',
    cc: 'NR',
    name_en: 'Nauru',
    desc_en: '',
    name_ru: 'Науру',
    desc_ru: '',
  },
  {
    mask: '+683-####',
    cc: 'NU',
    name_en: 'Niue',
    desc_en: '',
    name_ru: 'Ниуэ',
    desc_ru: '',
  },
  {
    mask: '+64 (###) ###-###',
    cc: 'NZ',
    name_en: 'New Zealand',
    desc_en: '',
    name_ru: 'Новая Зеландия',
    desc_ru: '',
  },
  {
    mask: '+64-##-###-###',
    cc: 'NZ',
    name_en: 'New Zealand',
    desc_en: '',
    name_ru: 'Новая Зеландия',
    desc_ru: '',
  },
  {
    mask: '+64 (###) ###-####',
    cc: 'NZ',
    name_en: 'New Zealand',
    desc_en: '',
    name_ru: 'Новая Зеландия',
    desc_ru: '',
  },
  {
    mask: '+968-##-###-###',
    cc: 'OM',
    name_en: 'Oman',
    desc_en: '',
    name_ru: 'Оман',
    desc_ru: '',
  },
  {
    mask: '+507-###-####',
    cc: 'PA',
    name_en: 'Panama',
    desc_en: '',
    name_ru: 'Панама',
    desc_ru: '',
  },
  {
    mask: '+507-6###-####',
    cc: 'PA',
    name_en: 'Panama',
    desc_en: '',
    name_ru: 'Панама',
    desc_ru: '',
  },
  {
    mask: '+51-###-###-##',
    cc: 'PE',
    name_en: 'Peru',
    desc_en: '',
    name_ru: 'Перу',
    desc_ru: '',
  },
  {
    mask: '+51 (###) ###-###',
    cc: 'PE',
    name_en: 'Peru',
    desc_en: '',
    name_ru: 'Перу',
    desc_ru: '',
  },
  {
    mask: '+51-###-###-####',
    cc: 'PE',
    name_en: 'Peru',
    desc_en: '',
    name_ru: 'Перу',
    desc_ru: '',
  },
  {
    mask: '+51-###-###-#####',
    cc: 'PE',
    name_en: 'Peru',
    desc_en: '',
    name_ru: 'Перу',
    desc_ru: '',
  },
  {
    mask: '+689-##-##-##',
    cc: 'PF',
    name_en: 'French Polynesia',
    desc_en: '',
    name_ru: 'Французская Полинезия  (Таити) ',
    desc_ru: '',
  },
  {
    mask: '+675 (###) ##-###',
    cc: 'PG',
    name_en: 'Papua New Guinea',
    desc_en: '',
    name_ru: 'Папуа-Новая Гвинея',
    desc_ru: '',
  },
  {
    mask: '+63 #-####-####',
    cc: 'PH',
    name_en: 'Philippines',
    desc_en: '',
    name_ru: 'Филиппины',
    desc_ru: '',
  },
  {
    mask: '+63 (###) ###-####',
    cc: 'PH',
    name_en: 'Philippines',
    desc_en: '',
    name_ru: 'Филиппины',
    desc_ru: '',
  },
  {
    mask: '+92 (###) ###-####',
    cc: 'PK',
    name_en: 'Pakistan',
    desc_en: '',
    name_ru: 'Пакистан',
    desc_ru: '',
  },
  {
    mask: '+48 (###) ###-###',
    cc: 'PL',
    name_en: 'Poland',
    desc_en: '',
    name_ru: 'Польша',
    desc_ru: '',
  },
  {
    mask: '+970-##-###-####',
    cc: 'PS',
    name_en: 'Palestine',
    desc_en: '',
    name_ru: 'Палестина',
    desc_ru: '',
  },
  {
    mask: '+351-##-###-####',
    cc: 'PT',
    name_en: 'Portugal',
    desc_en: '',
    name_ru: 'Португалия',
    desc_ru: '',
  },
  {
    mask: '+680-###-####',
    cc: 'PW',
    name_en: 'Palau',
    desc_en: '',
    name_ru: 'Палау',
    desc_ru: '',
  },
  {
    mask: '+595 (###) ###-###',
    cc: 'PY',
    name_en: 'Paraguay',
    desc_en: '',
    name_ru: 'Парагвай',
    desc_ru: '',
  },
  {
    mask: '+974-####-####',
    cc: 'QA',
    name_en: 'Qatar',
    desc_en: '',
    name_ru: 'Катар',
    desc_ru: '',
  },
  {
    mask: '+262-#####-####',
    cc: 'RE',
    name_en: 'Reunion',
    desc_en: '',
    name_ru: 'Реюньон',
    desc_ru: '',
  },
  {
    mask: '+40-##-###-####',
    cc: 'RO',
    name_en: 'Romania',
    desc_en: '',
    name_ru: 'Румыния',
    desc_ru: '',
  },
  {
    mask: '+40-#-##-###-####',
    cc: 'RO',
    name_en: 'Romania',
    desc_en: '',
    name_ru: 'Румыния',
    desc_ru: '',
  },
  {
    mask: '+381-##-###-###',
    cc: 'RS',
    name_en: 'Serbia',
    desc_en: '',
    name_ru: 'Сербия',
    desc_ru: '',
  },
  {
    mask: '+381-##-###-####',
    cc: 'RS',
    name_en: 'Serbia',
    desc_en: '',
    name_ru: 'Сербия',
    desc_ru: '',
  },
  {
    mask: '+7 (###) ###-##-##',
    cc: 'RU',
    name_en: 'Russia',
    desc_en: '',
    name_ru: 'Россия',
    desc_ru: '',
  },
  {
    mask: '+250 (###) ###-###',
    cc: 'RW',
    name_en: 'Rwanda',
    desc_en: '',
    name_ru: 'Руанда',
    desc_ru: '',
  },
  {
    mask: '+966-5-####-####',
    cc: 'SA',
    name_en: 'Saudi Arabia ',
    desc_en: 'mobile',
    name_ru: 'Саудовская Аравия ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+966-#-###-####',
    cc: 'SA',
    name_en: 'Saudi Arabia',
    desc_en: '',
    name_ru: 'Саудовская Аравия',
    desc_ru: '',
  },
  {
    mask: '+966-##-###-####',
    cc: 'JO',
    name_en: 'Jordan',
    desc_en: '',
    name_ru: 'Иордания',
    desc_ru: '',
  },
  {
    mask: '+677-###-####',
    cc: 'SB',
    name_en: 'Solomon Islands ',
    desc_en: 'mobile',
    name_ru: 'Соломоновы Острова ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+677-#####',
    cc: 'SB',
    name_en: 'Solomon Islands',
    desc_en: '',
    name_ru: 'Соломоновы Острова',
    desc_ru: '',
  },
  {
    mask: '+248-#-###-###',
    cc: 'SC',
    name_en: 'Seychelles',
    desc_en: '',
    name_ru: 'Сейшелы',
    desc_ru: '',
  },
  {
    mask: '+249-##-###-####',
    cc: 'SD',
    name_en: 'Sudan',
    desc_en: '',
    name_ru: 'Судан',
    desc_ru: '',
  },
  {
    mask: '+46-##-###-####',
    cc: 'SE',
    name_en: 'Sweden',
    desc_en: '',
    name_ru: 'Швеция',
    desc_ru: '',
  },
  {
    mask: '+65-####-####',
    cc: 'SG',
    name_en: 'Singapore',
    desc_en: '',
    name_ru: 'Сингапур',
    desc_ru: '',
  },
  {
    mask: '+290-####',
    cc: 'SH',
    name_en: 'Saint Helena',
    desc_en: '',
    name_ru: 'Остров Святой Елены',
    desc_ru: '',
  },
  {
    mask: '+290-####',
    cc: 'SH',
    name_en: 'Tristan da Cunha',
    desc_en: '',
    name_ru: 'Тристан-да-Кунья',
    desc_ru: '',
  },
  {
    mask: '+386-##-###-###',
    cc: 'SI',
    name_en: 'Slovenia',
    desc_en: '',
    name_ru: 'Словения',
    desc_ru: '',
  },
  {
    mask: '+421 (###) ###-###',
    cc: 'SK',
    name_en: 'Slovakia',
    desc_en: '',
    name_ru: 'Словакия',
    desc_ru: '',
  },
  {
    mask: '+232-##-######',
    cc: 'SL',
    name_en: 'Sierra Leone',
    desc_en: '',
    name_ru: 'Сьерра-Леоне',
    desc_ru: '',
  },
  {
    mask: '+378-####-######',
    cc: 'SM',
    name_en: 'San Marino',
    desc_en: '',
    name_ru: 'Сан-Марино',
    desc_ru: '',
  },
  {
    mask: '+221-##-###-####',
    cc: 'SN',
    name_en: 'Senegal',
    desc_en: '',
    name_ru: 'Сенегал',
    desc_ru: '',
  },
  {
    mask: '+252-##-###-###',
    cc: 'SO',
    name_en: 'Somalia',
    desc_en: '',
    name_ru: 'Сомали',
    desc_ru: '',
  },
  {
    mask: '+252-#-###-###',
    cc: 'SO',
    name_en: 'Somalia',
    desc_en: '',
    name_ru: 'Сомали',
    desc_ru: '',
  },
  {
    mask: '+252-#-###-###',
    cc: 'SO',
    name_en: 'Somalia ',
    desc_en: 'mobile',
    name_ru: 'Сомали ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+597-###-####',
    cc: 'SR',
    name_en: 'Suriname ',
    desc_en: 'mobile',
    name_ru: 'Суринам ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+597-###-###',
    cc: 'SR',
    name_en: 'Suriname',
    desc_en: '',
    name_ru: 'Суринам',
    desc_ru: '',
  },
  {
    mask: '+211-##-###-####',
    cc: 'SS',
    name_en: 'South Sudan',
    desc_en: '',
    name_ru: 'Южный Судан',
    desc_ru: '',
  },
  {
    mask: '+239-##-#####',
    cc: 'ST',
    name_en: 'Sao Tome and Principe',
    desc_en: '',
    name_ru: 'Сан-Томе и Принсипи',
    desc_ru: '',
  },
  {
    mask: '+503-##-##-####',
    cc: 'SV',
    name_en: 'El Salvador',
    desc_en: '',
    name_ru: 'Сальвадор',
    desc_ru: '',
  },
  {
    mask: '+1 (721) ###-####',
    cc: 'SX',
    name_en: 'Sint Maarten',
    desc_en: '',
    name_ru: 'Синт-Маартен',
    desc_ru: '',
  },
  {
    mask: '+963-##-####-###',
    cc: 'SY',
    name_en: 'Syrian Arab Republic',
    desc_en: '',
    name_ru: 'Сирийская арабская республика',
    desc_ru: '',
  },
  {
    mask: '+268-##-##-####',
    cc: 'SZ',
    name_en: 'Swaziland',
    desc_en: '',
    name_ru: 'Свазиленд',
    desc_ru: '',
  },
  {
    mask: '+1 (649) ###-####',
    cc: 'TC',
    name_en: 'Turks & Caicos',
    desc_en: '',
    name_ru: 'Тёркс и Кайкос',
    desc_ru: '',
  },
  {
    mask: '+235-##-##-##-##',
    cc: 'TD',
    name_en: 'Chad',
    desc_en: '',
    name_ru: 'Чад',
    desc_ru: '',
  },
  {
    mask: '+228-##-###-###',
    cc: 'TG',
    name_en: 'Togo',
    desc_en: '',
    name_ru: 'Того',
    desc_ru: '',
  },
  {
    mask: '+66-##-###-####',
    cc: 'TH',
    name_en: 'Thailand ',
    desc_en: 'mobile',
    name_ru: 'Таиланд ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+66-##-###-###',
    cc: 'TH',
    name_en: 'Thailand',
    desc_en: '',
    name_ru: 'Таиланд',
    desc_ru: '',
  },
  {
    mask: '+992-##-###-####',
    cc: 'TJ',
    name_en: 'Tajikistan',
    desc_en: '',
    name_ru: 'Таджикистан',
    desc_ru: '',
  },
  {
    mask: '+690-####',
    cc: 'TK',
    name_en: 'Tokelau',
    desc_en: '',
    name_ru: 'Токелау',
    desc_ru: '',
  },
  {
    mask: '+670-###-####',
    cc: 'TL',
    name_en: 'East Timor',
    desc_en: '',
    name_ru: 'Восточный Тимор',
    desc_ru: '',
  },
  {
    mask: '+670-77#-#####',
    cc: 'TL',
    name_en: 'East Timor',
    desc_en: 'Timor Telecom',
    name_ru: 'Восточный Тимор',
    desc_ru: 'Timor Telecom',
  },
  {
    mask: '+670-78#-#####',
    cc: 'TL',
    name_en: 'East Timor',
    desc_en: 'Timor Telecom',
    name_ru: 'Восточный Тимор',
    desc_ru: 'Timor Telecom',
  },
  {
    mask: '+993-#-###-####',
    cc: 'TM',
    name_en: 'Turkmenistan',
    desc_en: '',
    name_ru: 'Туркменистан',
    desc_ru: '',
  },
  {
    mask: '+216-##-###-###',
    cc: 'TN',
    name_en: 'Tunisia',
    desc_en: '',
    name_ru: 'Тунис',
    desc_ru: '',
  },
  {
    mask: '+676-#####',
    cc: 'TO',
    name_en: 'Tonga',
    desc_en: '',
    name_ru: 'Тонга',
    desc_ru: '',
  },
  {
    mask: '+90 (###) ###-####',
    cc: 'TR',
    name_en: 'Turkey',
    desc_en: '',
    name_ru: 'Турция',
    desc_ru: '',
  },
  {
    mask: '+1 (868) ###-####',
    cc: 'TT',
    name_en: 'Trinidad & Tobago',
    desc_en: '',
    name_ru: 'Тринидад и Тобаго',
    desc_ru: '',
  },
  {
    mask: '+688-90####',
    cc: 'TV',
    name_en: 'Tuvalu ',
    desc_en: 'mobile',
    name_ru: 'Тувалу ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+688-2####',
    cc: 'TV',
    name_en: 'Tuvalu',
    desc_en: '',
    name_ru: 'Тувалу',
    desc_ru: '',
  },
  {
    mask: '+886-#-####-####',
    cc: 'TW',
    name_en: 'Taiwan',
    desc_en: '',
    name_ru: 'Тайвань',
    desc_ru: '',
  },
  {
    mask: '+886-####-####',
    cc: 'TW',
    name_en: 'Taiwan',
    desc_en: '',
    name_ru: 'Тайвань',
    desc_ru: '',
  },
  {
    mask: '+255-##-###-####',
    cc: 'TZ',
    name_en: 'Tanzania',
    desc_en: '',
    name_ru: 'Танзания',
    desc_ru: '',
  },
  {
    mask: '+380 (##) ###-##-##',
    cc: 'UA',
    name_en: 'Ukraine',
    desc_en: '',
    name_ru: 'Украина',
    desc_ru: '',
  },
  {
    mask: '+256 (###) ###-###',
    cc: 'UG',
    name_en: 'Uganda',
    desc_en: '',
    name_ru: 'Уганда',
    desc_ru: '',
  },
  {
    mask: '+44-##-####-####',
    cc: 'UK',
    name_en: 'United Kingdom',
    desc_en: '',
    name_ru: 'Великобритания',
    desc_ru: '',
  },
  {
    mask: '+598-#-###-##-##',
    cc: 'UY',
    name_en: 'Uruguay',
    desc_en: '',
    name_ru: 'Уругвай',
    desc_ru: '',
  },
  {
    mask: '+998-##-###-####',
    cc: 'UZ',
    name_en: 'Uzbekistan',
    desc_en: '',
    name_ru: 'Узбекистан',
    desc_ru: '',
  },
  {
    mask: '+39-6-698-#####',
    cc: 'VA',
    name_en: 'Vatican City',
    desc_en: '',
    name_ru: 'Ватикан',
    desc_ru: '',
  },
  {
    mask: '+1 (784) ###-####',
    cc: 'VC',
    name_en: 'Saint Vincent & the Grenadines',
    desc_en: '',
    name_ru: 'Сент-Винсент и Гренадины',
    desc_ru: '',
  },
  {
    mask: '+58 (###) ###-####',
    cc: 'VE',
    name_en: 'Venezuela',
    desc_en: '',
    name_ru: 'Венесуэла',
    desc_ru: '',
  },
  {
    mask: '+1 (284) ###-####',
    cc: 'VG',
    name_en: 'British Virgin Islands',
    desc_en: '',
    name_ru: 'Британские Виргинские острова',
    desc_ru: '',
  },
  {
    mask: '+1 (340) ###-####',
    cc: 'VI',
    name_en: 'US Virgin Islands',
    desc_en: '',
    name_ru: 'Американские Виргинские острова',
    desc_ru: '',
  },
  {
    mask: '+84-##-####-###',
    cc: 'VN',
    name_en: 'Vietnam',
    desc_en: '',
    name_ru: 'Вьетнам',
    desc_ru: '',
  },
  {
    mask: '+84 (###) ####-###',
    cc: 'VN',
    name_en: 'Vietnam',
    desc_en: '',
    name_ru: 'Вьетнам',
    desc_ru: '',
  },
  {
    mask: '+678-##-#####',
    cc: 'VU',
    name_en: 'Vanuatu ',
    desc_en: 'mobile',
    name_ru: 'Вануату ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+678-#####',
    cc: 'VU',
    name_en: 'Vanuatu',
    desc_en: '',
    name_ru: 'Вануату',
    desc_ru: '',
  },
  {
    mask: '+681-##-####',
    cc: 'WF',
    name_en: 'Wallis and Futuna',
    desc_en: '',
    name_ru: 'Уоллис и Футуна',
    desc_ru: '',
  },
  {
    mask: '+685-##-####',
    cc: 'WS',
    name_en: 'Samoa',
    desc_en: '',
    name_ru: 'Самоа',
    desc_ru: '',
  },
  {
    mask: '+967-###-###-###',
    cc: 'YE',
    name_en: 'Yemen ',
    desc_en: 'mobile',
    name_ru: 'Йемен ',
    desc_ru: 'мобильные',
  },
  {
    mask: '+967-#-###-###',
    cc: 'YE',
    name_en: 'Yemen',
    desc_en: '',
    name_ru: 'Йемен',
    desc_ru: '',
  },
  {
    mask: '+967-##-###-###',
    cc: 'YE',
    name_en: 'Yemen',
    desc_en: '',
    name_ru: 'Йемен',
    desc_ru: '',
  },
  {
    mask: '+27-##-###-####',
    cc: 'ZA',
    name_en: 'South Africa',
    desc_en: '',
    name_ru: 'Южно-Африканская Респ.',
    desc_ru: '',
  },
  {
    mask: '+27-###-###-####',
    cc: 'ZA',
    name_en: 'South Africa',
    desc_en: '',
    name_ru: 'Южно-Африканская Респ.',
    desc_ru: '',
  },
  {
    mask: '+260-##-###-####',
    cc: 'ZM',
    name_en: 'Zambia',
    desc_en: '',
    name_ru: 'Замбия',
    desc_ru: '',
  },
  {
    mask: '+263-#-######',
    cc: 'ZW',
    name_en: 'Zimbabwe',
    desc_en: '',
    name_ru: 'Зимбабве',
    desc_ru: '',
  },
  {
    mask: '+1 (###) ###-####',
    cc: 'US',
    name_en: 'USA',
    desc_en: '',
    name_ru: 'США',
    desc_ru: '',
  },
  {
    mask: '+1 (###) ###-####',
    cc: 'CA',
    name_en: 'Canada',
    desc_en: '',
    name_ru: 'Канада',
    desc_ru: '',
  },
  {
    mask: '0##-#######',
    cc: '??',
    name_en: 'Unknown',
    desc_en: '',
    name_ru: 'Неизвестно',
    desc_ru: '',
  },
]
