import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_ea76b9be from 'nuxt_plugin_plugin_ea76b9be' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_8bb05f0a from 'nuxt_plugin_sentryserver_8bb05f0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_546e5ef3 from 'nuxt_plugin_sentryclient_546e5ef3' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_recaptcha_301d92a6 from 'nuxt_plugin_recaptcha_301d92a6' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_6a8b095f from 'nuxt_plugin_cookieuniversalnuxt_6a8b095f' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vlazyload_97e62136 from 'nuxt_plugin_vlazyload_97e62136' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_pluginutils_1f3b8938 from 'nuxt_plugin_pluginutils_1f3b8938' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_16c17faf from 'nuxt_plugin_pluginrouting_16c17faf' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7777bbc0 from 'nuxt_plugin_pluginmain_7777bbc0' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_3f1c9092 from 'nuxt_plugin_axios_3f1c9092' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_4764af7c from 'nuxt_plugin_deviceplugin_4764af7c' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_ga_600ffc1a from 'nuxt_plugin_ga_600ffc1a' // Source: ../plugins/metrics/ga.js (mode: 'client')
import nuxt_plugin_yandexmetric_3b911be4 from 'nuxt_plugin_yandexmetric_3b911be4' // Source: ../plugins/metrics/yandex-metric.js (mode: 'client')
import nuxt_plugin_gtm_b9d1f18c from 'nuxt_plugin_gtm_b9d1f18c' // Source: ../plugins/metrics/gtm.js (mode: 'client')
import nuxt_plugin_vk_60e7e85f from 'nuxt_plugin_vk_60e7e85f' // Source: ../plugins/metrics/vk.js (mode: 'client')
import nuxt_plugin_ratingmail_27e767da from 'nuxt_plugin_ratingmail_27e767da' // Source: ../plugins/metrics/rating-mail.js (mode: 'client')
import nuxt_plugin_fbpixel_5316a1c2 from 'nuxt_plugin_fbpixel_5316a1c2' // Source: ../plugins/metrics/fb-pixel.js (mode: 'client')
import nuxt_plugin_sbjs_4f456f68 from 'nuxt_plugin_sbjs_4f456f68' // Source: ../plugins/sbjs.js (mode: 'client')
import nuxt_plugin_scrollpolyfill_0ca2ad42 from 'nuxt_plugin_scrollpolyfill_0ca2ad42' // Source: ../plugins/scroll-polyfill.js (mode: 'client')
import nuxt_plugin_vuedatepicker_cf85980c from 'nuxt_plugin_vuedatepicker_cf85980c' // Source: ../plugins/vue-datepicker (mode: 'client')
import nuxt_plugin_vueinputmask_06831890 from 'nuxt_plugin_vueinputmask_06831890' // Source: ../plugins/vue-inputmask (mode: 'client')
import nuxt_plugin_vuemultiselect_57bfe612 from 'nuxt_plugin_vuemultiselect_57bfe612' // Source: ../plugins/vue-multiselect (mode: 'all')
import nuxt_plugin_vuematchheights_904e15b4 from 'nuxt_plugin_vuematchheights_904e15b4' // Source: ../plugins/vue-match-heights (mode: 'client')
import nuxt_plugin_vuejsmodal_26c7eee7 from 'nuxt_plugin_vuejsmodal_26c7eee7' // Source: ../plugins/vue-js-modal (mode: 'client')
import nuxt_plugin_vueawesomeswiper_0e14f460 from 'nuxt_plugin_vueawesomeswiper_0e14f460' // Source: ../plugins/vue-awesome-swiper (mode: 'client')
import nuxt_plugin_vuescroll_009a2ddc from 'nuxt_plugin_vuescroll_009a2ddc' // Source: ../plugins/vuescroll (mode: 'all')
import nuxt_plugin_mixins_6f76c2a4 from 'nuxt_plugin_mixins_6f76c2a4' // Source: ../plugins/mixins.js (mode: 'all')
import nuxt_plugin_gdprinit_a85356f2 from 'nuxt_plugin_gdprinit_a85356f2' // Source: ../plugins/gdpr-init (mode: 'client')
import nuxt_plugin_analyticevents_597bdba2 from 'nuxt_plugin_analyticevents_597bdba2' // Source: ../plugins/analytic-events (mode: 'client')
import nuxt_plugin_directionplugin_417a4f18 from 'nuxt_plugin_directionplugin_417a4f18' // Source: ../plugins/direction-plugin (mode: 'all')
import nuxt_plugin_youtubeclient_9bcd036c from 'nuxt_plugin_youtubeclient_9bcd036c' // Source: ../plugins/youtube.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Algorithmics","script":[{"defer":true,"async":true,"src":"https:\u002F\u002Funpkg.com\u002Fsmoothscroll-polyfill@0.4.4\u002Fdist\u002Fsmoothscroll.min.js"},{"defer":true,"async":true,"src":"https:\u002F\u002Funpkg.com\u002Fsmoothscroll-anchor-polyfill"}],"htmlAttrs":{"lang":"ru"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Algorithmics"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fmain.ico"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_ea76b9be === 'function') {
    await nuxt_plugin_plugin_ea76b9be(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_8bb05f0a === 'function') {
    await nuxt_plugin_sentryserver_8bb05f0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_546e5ef3 === 'function') {
    await nuxt_plugin_sentryclient_546e5ef3(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_301d92a6 === 'function') {
    await nuxt_plugin_recaptcha_301d92a6(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_6a8b095f === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_6a8b095f(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_97e62136 === 'function') {
    await nuxt_plugin_vlazyload_97e62136(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1f3b8938 === 'function') {
    await nuxt_plugin_pluginutils_1f3b8938(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_16c17faf === 'function') {
    await nuxt_plugin_pluginrouting_16c17faf(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7777bbc0 === 'function') {
    await nuxt_plugin_pluginmain_7777bbc0(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3f1c9092 === 'function') {
    await nuxt_plugin_axios_3f1c9092(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_4764af7c === 'function') {
    await nuxt_plugin_deviceplugin_4764af7c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ga_600ffc1a === 'function') {
    await nuxt_plugin_ga_600ffc1a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_yandexmetric_3b911be4 === 'function') {
    await nuxt_plugin_yandexmetric_3b911be4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_b9d1f18c === 'function') {
    await nuxt_plugin_gtm_b9d1f18c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vk_60e7e85f === 'function') {
    await nuxt_plugin_vk_60e7e85f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ratingmail_27e767da === 'function') {
    await nuxt_plugin_ratingmail_27e767da(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fbpixel_5316a1c2 === 'function') {
    await nuxt_plugin_fbpixel_5316a1c2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sbjs_4f456f68 === 'function') {
    await nuxt_plugin_sbjs_4f456f68(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollpolyfill_0ca2ad42 === 'function') {
    await nuxt_plugin_scrollpolyfill_0ca2ad42(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedatepicker_cf85980c === 'function') {
    await nuxt_plugin_vuedatepicker_cf85980c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinputmask_06831890 === 'function') {
    await nuxt_plugin_vueinputmask_06831890(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemultiselect_57bfe612 === 'function') {
    await nuxt_plugin_vuemultiselect_57bfe612(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuematchheights_904e15b4 === 'function') {
    await nuxt_plugin_vuematchheights_904e15b4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejsmodal_26c7eee7 === 'function') {
    await nuxt_plugin_vuejsmodal_26c7eee7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueawesomeswiper_0e14f460 === 'function') {
    await nuxt_plugin_vueawesomeswiper_0e14f460(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescroll_009a2ddc === 'function') {
    await nuxt_plugin_vuescroll_009a2ddc(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_6f76c2a4 === 'function') {
    await nuxt_plugin_mixins_6f76c2a4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gdprinit_a85356f2 === 'function') {
    await nuxt_plugin_gdprinit_a85356f2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analyticevents_597bdba2 === 'function') {
    await nuxt_plugin_analyticevents_597bdba2(app.context, inject)
  }

  if (typeof nuxt_plugin_directionplugin_417a4f18 === 'function') {
    await nuxt_plugin_directionplugin_417a4f18(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_youtubeclient_9bcd036c === 'function') {
    await nuxt_plugin_youtubeclient_9bcd036c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    const { route } = router.resolve(app.context.route.fullPath)
    // Ignore 404s rather than blindly replacing URL
    if (!route.matched.length && process.client) {
      return resolve()
    }
    router.replace(route, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
